import XCheckpointRepository from '../../domain/repositories/XCheckpointRepository';
import XCheckpointDataStore from '../datastores/XCheckpointDataStore';
import XCheckpointDataStoreFactory from '../factories/XCheckpointDataStoreFactory';

export default class XCheckpointDataRepository
  implements XCheckpointRepository
{
  writeLog(nameFunc: string, idTransaction: string) {
    const xcheckpointDataStore: XCheckpointDataStore =
      XCheckpointDataStoreFactory.create();
    return xcheckpointDataStore.writeLog(nameFunc, idTransaction);
  }
}
