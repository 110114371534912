const reducerBase = () => {
  const INITIAL_STATE = {
    isLoading: false,
    error: null
  };

  const request = (state: any) => ({
    ...state,
    isLoading: true,
    error: null,
    data: undefined
  });

  const success = (state: any, action: any) => ({
    ...state,
    isLoading: false,
    data: action.data,
    isSuccess: true,
    error: null
  });

  const failure = (state: any, action: any) => ({
    ...state,
    isLoading: false,
    data: null,
    error: action.error,
    isSuccess: false
  });

  const clear = () => ({
    ...INITIAL_STATE
  });

  return { INITIAL_STATE, request, success, failure, clear };
};

export default reducerBase;
