import React from 'react';
import styled from 'styled-components';
import BrandImage from '../../assets/images/logo-small.svg';
import BrandImageXl from '../../assets/images/logo-large.svg';

const BrandLogo = styled.img`
  width: 152px;
  height: 26px;
  display: flex;
  justify-content: center;

  @media (min-width: 768px) {
    width: 198px;
    height: 34px;
    background: url(${BrandImageXl}) no-repeat center;
  }
`;

function BrandHelp() {
  return <BrandLogo alt="UTP+Portal" src={BrandImage} />;
}

BrandHelp.defaultProps = {};

export default BrandHelp;
