import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  // View actions
  getArticleDetail: null,
  // Reducer
  getArticleDetailRequest: null,
  getArticleDetailSuccess: null,
  getArticleDetailFailure: ['error']
});

export { Creators, Types };
