import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  // View actions
  selectCategory: ['categoryId'],
  // Reducer
  selectCategoryRequest: null,
  selectCategorySuccess: null,
  selectCategoryFailure: ['error'],
  selectCategoryClear: null
});

export { Creators, Types };
