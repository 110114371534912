import React from 'react';
import { Block, Icon, Label } from '../../../../components';
import { Article, SubCategory } from '../../types';
import './styles.css';
import IDHelpers from '../../../../../core/helpers/IDHelper';
import BreadCrumb from '../../components/breadCrumb/BreadCrumb';
import { CenterWrapper, ContainerKnowledgeBase } from '../../components/styled';
import Spinner from '../articleDetail/Spinner';

interface ArticleListViewProps {
  articles: Article[];
  articlesIsLoading: boolean;
  selectedSubCategory: SubCategory;
  selectArticle: (article: Article) => void;
}

const ArticleListView = ({
  articles,
  articlesIsLoading,
  selectedSubCategory,
  selectArticle
}: ArticleListViewProps) => (
  <ContainerKnowledgeBase data-testid="cmp-articleList-view">
    <CenterWrapper>
      <BreadCrumb />
      <Block flex className="articleSubTitle">
        <Label
          text={selectedSubCategory?.name}
          color="#000000"
          fontWeight="900"
          size={16}
          lineHeight={24}
        />
      </Block>
      <Block customized padding="0 0 56px 0">
        {articlesIsLoading && <Spinner />}
        {articles?.map((article) => (
          <button
            data-testid="cmp-article-list-item"
            type="button"
            key={IDHelpers.generateID()}
            className="articleListItem"
            onClick={() => selectArticle(article)}
          >
            <div>
              <Icon name="file-text" size={18} /> {article?.name}
            </div>
          </button>
        ))}
      </Block>
    </CenterWrapper>
  </ContainerKnowledgeBase>
);

export default ArticleListView;
