import styled from 'styled-components';

const LoaderElement = styled.div.attrs((props: any) => ({
  height: `${props.height || 100}px`
}))`
  color: #5b36f2;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 524px;
  width: 100%;
  display: flex;
  flex-direction: column;
  * {
    box-sizing: border-box;
  }
  .spinnerCircle {
    width: 40px;
    height: 40px;
    background-color: #fff;
    padding: 9px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .loadingName {
    margin-bottom: 8px;
  }
`;
export default LoaderElement;
