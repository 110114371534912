import React from 'react';
import parse from 'html-react-parser';
import thumpsDown from '../../../../../assets/icons/thumbs-down.svg';
import thumpsUp from '../../../../../assets/icons/thumbs-up-v2.svg';
import messageSuccessImg from '../../../../../assets/illustrations/message_success.svg';
import messageFailImg from '../../../../../assets/illustrations/message_fail.svg';
import BreadCrumb from '../../components/breadCrumb/BreadCrumb';
import './styles.css';
import { Block } from '../../../../components';
import InputField from './components/InputField/InputField';
import Spinner from './Spinner';
import Processing from '../../../../components/Processing';

interface ArticleDetailViewProps {
  isLoadingArticle: boolean;
  isLoadingQualification: boolean;
  statusResponse: boolean;
  responseQualification: any;
  dataArticle: any;
  errorResponse: boolean;
  sendQualification: (qualification: any) => void;
  clearError: () => void;
  fields: any;
  errors: any;
  setFieldValue: ({ name, value }: any) => void;
  setFieldError: ({ name, value }: any) => void;
  thumpUpValue: boolean;
  tryAgainSend: () => void;
  show: boolean;
  refNotBtn: React.MutableRefObject<any>;
  refYesBtn: React.MutableRefObject<any>;
  refImgNot: React.MutableRefObject<any>;
  refNotTitle: React.MutableRefObject<any>;
  refYesTitle: React.MutableRefObject<any>;
  refYesImg: React.MutableRefObject<any>;
  handlerClickNo: () => void;
  handlerClickSi: () => void;
}

const ArticleDetailView = ({
  isLoadingArticle,
  isLoadingQualification,
  responseQualification,
  dataArticle,
  sendQualification,
  statusResponse,
  errorResponse,
  clearError,
  fields,
  errors,
  setFieldValue,
  setFieldError,
  thumpUpValue,
  show,
  tryAgainSend,
  refNotBtn,
  refYesBtn,
  refImgNot,
  refNotTitle,
  refYesTitle,
  refYesImg,
  handlerClickNo,
  handlerClickSi
}: ArticleDetailViewProps) => {
  const refNotBtnValue = refNotBtn;
  const refYesBtnValue = refYesBtn;
  const refImgNotValue = refImgNot;
  const refNotTitleValue = refNotTitle;
  const refYesTitleValue = refYesTitle;
  const refYesImgValue = refYesImg;

  const { feedback } = fields;
  const { feedback: errorFeedback } = errors;

  const validateField = ({ name, value }: any) => {
    const validator: { [key: string]: boolean } = {
      feedback: value.length > 480
    };
    setFieldError({ name, value: validator[name] });
    setFieldValue({ name, value });
  };

  const handlerSend = () => {
    const data = {
      articleCode: dataArticle?.code,
      thumpUp: thumpUpValue,
      feedback
    };
    sendQualification(data);
  };

  const containerQualification = () => (
    <>
      <h1 className="article__title">
        ¿Te pareció útil la información brindada?
      </h1>
      <div className="article_btns">
        <div className="article__thumps_up">
          <button
            type="button"
            onClick={handlerClickSi}
            data-testid="cmp-click-yes"
            ref={refYesBtnValue}
            className="thumps_up"
          >
            <img
              src={thumpsUp}
              className="thumps_up__img"
              alt="thumpsUp"
              ref={refYesImgValue}
            />
          </button>
          <h1 ref={refYesTitleValue} className="title_si">
            SI
          </h1>
        </div>
        <div className="article__thumps_down">
          <button
            type="button"
            onClick={handlerClickNo}
            ref={refNotBtnValue}
            data-testid="cmp-click-no"
            className="thumps_down"
          >
            <img
              src={thumpsDown}
              className="thumps_down__img"
              alt="thumpsDown"
              ref={refImgNotValue}
            />
          </button>
          <h1 ref={refNotTitleValue} className="title_no">
            NO
          </h1>
        </div>
      </div>

      {/*  el contador */}

      {show && (
        <>
          <hr className="detail__hr" />
          <div className="container__form">
            <div className="container__form__send">
              {thumpUpValue === true ? (
                <h1 className="form__text">
                  ¡Nos alegra poder ayudarte! Cuéntanos, ¿hay algo más en que
                  podemos apoyarte? <b>(Opcional)</b>
                </h1>
              ) : (
                <h1 className="form__text">
                  Lamentamos no haberte ayudado ¿Cómo podríamos mejorar nuestra
                  información? <b>(Opcional)</b>
                </h1>
              )}

              <InputField
                value={feedback}
                setValue={validateField}
                placeholder="Escribe aquí el detalle"
                textarea
                showCount
                className={
                  feedback.length > 480 ? 'input_field_exceeded' : 'input_field'
                }
                error={errorFeedback}
                maxLength={480}
                errorText="Excediste el número de caracteres"
                name="feedback"
              />
              <h1 className="text_comentar">
                Este espacio es sólo para comentarios, por lo que no recibirás
                una respuesta*
              </h1>
            </div>
            <button
              type="button"
              onClick={handlerSend}
              data-testid="cmp-send-qualification"
              className={feedback.length > 480 ? 'btn_disabled' : 'btn_send'}
            >
              Enviar
            </button>
          </div>
        </>
      )}
    </>
  );

  const messageSuccess = () => (
    <>
      <hr className="message__hr_up" />
      <div className="message">
        <div className="message__img__success">
          <img src={messageSuccessImg} alt="messageSuccess" />
        </div>
        <h1 className="message__title">
          ¡Gracias por ayudarnos a seguir mejorando!
        </h1>
        <h1 className="message__subtitle">
          Hemos recibido tu(s) comentarios(s)
        </h1>
      </div>
      <hr className="message__hr" />
    </>
  );

  const messageFail = () => (
    <>
      <hr className="message__hr_up" />
      <div className="message">
        <div className="message__img__success">
          <img src={messageFailImg} alt="messageFailImg" />
        </div>
        <h1 className="message__title">¡Oh no!</h1>
        <h1 className="message__subtitle">
          Parece que hubo un error en el envio de tu comentario
        </h1>
        <div className="message__btns">
          <button
            data-testid="cmp-try-again"
            className="message__btn__1"
            type="button"
            onClick={tryAgainSend}
          >
            Volver intentar
          </button>
          <button
            type="button"
            className="message__btn__2"
            onClick={clearError}
            data-testid="cmp-no-thakns"
          >
            No, gracias
          </button>
        </div>
      </div>
      <hr className="message__hr" />
    </>
  );

  const functionsResponse = () => {
    if (responseQualification === null && errorResponse === null) {
      return containerQualification();
    }
    if (
      !isLoadingQualification &&
      responseQualification &&
      statusResponse === true &&
      errorResponse === null
    ) {
      return messageSuccess();
    }
    return messageFail();
  };

  return (
    <div data-testid="cmp-articleDetail-view">
      <Processing isOpen={isLoadingQualification} />
      <Block flex className="article__body" color="#ffff">
        <Block className="article__detail">
          <div className="wrapBreadcrumb">
            <BreadCrumb />
          </div>
          {isLoadingArticle ? (
            <Spinner />
          ) : (
            <div className="container__detail">
              <div className="detail__pdf">
                <figure className="article__container__pdf">
                  {parse(`${dataArticle?.html}`)}
                </figure>
              </div>

              <div className="detail__information">
                <div className="article__container__votar">
                  {functionsResponse()}
                </div>
              </div>
            </div>
          )}
        </Block>
      </Block>
    </div>
  );
};
export default ArticleDetailView;
