const paletteUTP = {
  alert: '#fabd00',
  error: '#e8212b',
  success: '#2d8746',
  support: '#8f80f8',

  backgroundUTP: '#ecf4f9',
  backgroundDisabled: '#ecf4f9',
  background01: '#ecf4f9',
  background02: '#ecf4f9',
  background03: '#ecf4f9',
  background04: '#ecf4f9',

  menuBackgroundGradient1: '#ff385b',
  menuBackgroundGradient2: '#ffb59a',
  menuIcon: '#ffffff',

  NavigationBarLine: '#fc4a61',
  interactionPrimary: '#5b36f2',
  interactionSecondary: '#1a055d',

  neutral01: '#7a959f',
  neutral02: '#283044',
  neutral03: '#ffffff',

  textPrimary: '#5b36f2',
  textSecondary: '#1a055d',
  text01: '#4f6168',
  text02: '#161d1f',
  text03: '#ffffff',
  textDisabled: '#8090bb',

  decorative01: '#0060ab',
  decorative02: '#8a4b60',
  decorative03: '#0079d6',
  decorative04: '#bd5c00',
  decorative05: '#83429f',
  decorative06: '#048583'
};

export default paletteUTP;
