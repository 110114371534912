import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Creators as subcategoriesActions } from '../../../../../domain/useCases/knowledgeBase/getSubcategoriesUseCase/actions';
import { Creators as articlesActions } from '../../../../../domain/useCases/knowledgeBase/getArticleListUseCase/actions';
import { Creators as categoriesActions } from '../../../../../domain/useCases/knowledgeBase/getCategoriesUseCase/actions';
import { Creators as selectCategoryActions } from '../../../../../domain/useCases/knowledgeBase/selectCategory/actions';
// import { Creators as articleActions } from '../../../../../domain/useCases/knowledgeBase/getArticleUseCase/actions';
import { Creators as breadcrumbsActions } from '../../../../../domain/useCases/knowledgeBase/breadcrumbUseCase/actions';
import { Creators as registerQualificationActions } from '../../../../../domain/useCases/knowledgeBase/registerCommentArticleUseCase/actions';
import routes from '../../../../components/Routes/routingMap';
import { SubCategory } from '../../types';

const useSubCategory = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { categoryId }: any = useParams();

  useEffect(() => {
    if (categoryId) dispatch(selectCategoryActions.selectCategory(categoryId));
  }, []);

  const { data: selectedCategory } = useSelector(
    (state: any) => state.selectCategory
  );

  useEffect(() => {
    if (selectedCategory) {
      // if (selectedCategory.code === null) history.push('/');
      dispatch(registerQualificationActions.registerCommentClear());
      dispatch(breadcrumbsActions.setPosition(1));
      dispatch(subcategoriesActions.getSubCategories(selectedCategory?.code));
      dispatch(subcategoriesActions.selectSubCategory(null));
      dispatch(articlesActions.selectedArticle(null));
    }
  }, [selectedCategory]);

  const {
    data: subcategories,
    isLoading: isLoadingSubCategories,
    selectedSubCategory
  } = useSelector((state: any) => state.subCategoryKnowledgeBase);

  const selectSubCategory = (subCategory: SubCategory) => {
    if (subCategory.isArticle) {
      dispatch(articlesActions.selectedArticle(subCategory));
      // dispatch(articleActions.getArticle(subCategory.code));
      dispatch(subcategoriesActions.selectSubCategory(null));
      dispatch(breadcrumbsActions.setPosition(3));
      history.push(routes.help.article.replace(':articleId', subCategory.code));
    } else {
      dispatch(subcategoriesActions.selectSubCategory(subCategory));
      dispatch(
        articlesActions.getArticles(selectedCategory.code, subCategory.code)
      );
      dispatch(breadcrumbsActions.setPosition(2));
      history.push(routes.help.articles);
    }
  };

  return {
    subcategories,
    isLoadingSubCategories,
    selectedCategory,
    selectedSubCategory,
    selectSubCategory
  };
};

export default useSubCategory;
