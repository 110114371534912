import React from 'react';
import parse from 'html-react-parser';
import OutsideClickHandler from 'react-outside-click-handler';
import { CcLoader } from '@utp/components-react';
import TooltipProcedure from '../../components/TooltipProcedure/TooltipProcedure';
import {
  Header,
  Title,
  ContainerList,
  InputSearchWrapper,
  CenterWrapper,
  Buttoninfo,
  Buttonstyle,
  SectionDigitalPlatforms,
  TitleDigitalPlatforms,
  RowCardDigitalPlatforms,
  CardDigitalPlatforms,
  ImagenDigitalPlatforms,
  ContainerInterestsExternal,
  TitlesInterestsExternal,
  TitleInterests
} from './components/styled';
import CategoryCard from './components/Category';
import IDHelpers from '../../../core/helpers/IDHelper';
import { Icon } from '../../components';
import { Article, Category } from './types';
import './styles.css';
import Spinner from './modules/articleDetail/Spinner';
import constants from '../../../core/constants';
import KnowledgeBaseFooterView from '../../_components/KnowledgeBaseFooter/KnowledgeBaseFooterView';
import IconClass from '../../../assets/icons/home/class.svg';
import IconUTPClass from '../../../assets/icons/home/utp-class.svg';
import IconPortal from '../../../assets/icons/home/portal.svg';
import IconUTPPOrtal from '../../../assets/icons/home/utp-portal.svg';
import IconSmallCrosses from '../../../assets/icons/home/smallCrosses.svg';

const InputSearch = ({
  value,
  filterMetadata,
  isLoadingData,
  setValue,
  selectItem
}: {
  value: string;
  filterMetadata: any[];
  isLoadingData: boolean;
  setValue: (value: string) => void;
  selectItem: (item: any) => void;
}) => (
  <InputSearchWrapper data-testid="cmp-InputSearch">
    <input
      data-testid="cmp-inputSearch-input"
      id="inputSearch"
      type="text"
      className={`inputSearch ${value ? 'inputSearchFilled' : ''}`}
      placeholder="Buscar"
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      autoComplete="off"
    />
    <button
      data-testid="cmp-inputSearch-button"
      type="button"
      className="inputButton"
      disabled={!value}
      onClick={() => {
        if (value) {
          setValue('');
          const input = document.getElementById('inputSearch');
          input?.focus();
        }
      }}
    >
      {isLoadingData && <CcLoader size={20} className="searchLoader" />}
      {!isLoadingData && (
        <Icon name={value ? 'x' : 'search'} size={20} color="var(--primary)" />
      )}
    </button>
    {value && (
      <div className="menu-wrapper boxShadow" data-testid="cmp-menu-wrapper">
        <OutsideClickHandler
          onOutsideClick={() => {
            setValue('');
            const inputS = document.getElementById('inputSearch');
            inputS?.focus();
          }}
        >
          <ul>
            {filterMetadata?.map((meta) => {
              const newValue = value.replaceAll(' ', '|');
              const newName = meta.name.replaceAll(' ', '|');
              let text1 = newName.replaceAll(
                newValue,
                `<mark>${newValue}</mark>`
              );
              text1 = text1.replaceAll('|', '&nbsp;');
              return (
                <li key={IDHelpers.generateIDStr()}>
                  <button
                    type="button"
                    onClick={() => selectItem(meta)}
                    data-testid="cmp-button-article-found"
                  >
                    {parse(text1)}
                  </button>
                </li>
              );
            })}
            {filterMetadata === undefined ||
              (filterMetadata.length === 0 && (
                <li key={IDHelpers.generateIDStr()}>
                  <button type="button" className="empty">
                    <Icon name="info" size={20} color="var(--info)" />
                    No se ha encontrado resultado
                  </button>
                </li>
              ))}
          </ul>
        </OutsideClickHandler>
      </div>
    )}
  </InputSearchWrapper>
);
interface KnowledgeBaseViewProps {
  isValidToken: boolean;
  metadata: Article[];
  categoryList: Category[];
  isLoadingCategories: boolean;
  searchText: string;
  isLoadingMetadata: boolean;
  changeSearchText: (text: string) => void;
  selectCategory: (category: Category) => void;
  selectArticle: (article: Article) => void;
  handlerRedirectNewPage: (url: any) => void;
}

const KnowledgeBaseView = ({
  isValidToken,
  metadata,
  categoryList,
  isLoadingCategories,
  searchText,
  isLoadingMetadata,
  changeSearchText,
  selectCategory,
  selectArticle,
  handlerRedirectNewPage
}: KnowledgeBaseViewProps) => (
  <div data-testid="cmp-knowledgebase-view">
    <Header>
      <CenterWrapper>
        <Title>¿Necesitas ayuda?</Title>
        <InputSearch
          value={searchText}
          setValue={changeSearchText}
          filterMetadata={metadata}
          selectItem={selectArticle}
          isLoadingData={isLoadingMetadata}
        />
        <Buttonstyle>
          {/* <a
            href={
              isValidToken
                ? `${constants.URL_PORTAL}/tramites/ticketera/registro`
                : '/login'
            }
            target={isValidToken ? '_blank' : '_self'}
            rel="noreferrer"
          >
            Registra tu solicitud
          </a> */}
          <Buttoninfo
            data-testid="cmp-registrar-button-utp-button"
            href={
              isValidToken
                ? `${constants.URL_PORTAL}/tramites/ticketera/registro`
                : '/login2'
            }
            target={isValidToken ? '_blank' : '_self'}
            rel="noreferrer"
          >
            Registra tu solicitud
          </Buttoninfo>
          {!isValidToken ? (
            <TooltipProcedure
              hasButton
              tooltiptext="Solo podrás ingresar una solicitud si eres estudiante y has iniciado sesión."
              bgColor="#000F37"
              bottom="75px"
              fadeout="7s"
              width="235px"
              minWidth="992px"
            >
              <button type="button" className="tooltipButton">
                <Icon name="info" size={20} />
              </button>
            </TooltipProcedure>
          ) : (
            <></>
          )}
        </Buttonstyle>
      </CenterWrapper>
    </Header>
    <ContainerList>
      <CenterWrapper>
        {isLoadingCategories ? (
          <Spinner />
        ) : (
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-md lg:gap-xlg">
            {categoryList?.map((category) => (
              <CategoryCard
                key={IDHelpers.generateIDStr()}
                code={category?.code}
                name={category?.name}
                onClick={() => selectCategory(category)}
              />
            ))}
          </div>
        )}
      </CenterWrapper>
    </ContainerList>
    <CenterWrapper>
      <SectionDigitalPlatforms>
        <TitleDigitalPlatforms>
          Tus Plataformas digitales:
        </TitleDigitalPlatforms>
        <RowCardDigitalPlatforms>
          {/* <CardDigitalPlatforms
            height="124px"
            width="264px"
            data-testid="cmp-btn-utp+app"
            onClick={() => {
              handlerRedirectNewPage(
                'https://play.google.com/store/apps/details?id=com.inlearning'
              );
            }}
            formTarget="_blank"
            type="button"
          >
            <ImagenDigitalPlatforms src={IconApp} margin="6px 0px 0px 12px" />
            <ImagenDigitalPlatforms
              src={IconSmallCrosses}
              margin="-4px 0px 0px -10px"
            />
            <ContainerInterestsExternal>
              <TitlesInterestsExternal
                style={{
                  alignItems: 'baseline',
                  marginTop: '11px',
                  marginLeft: '-2px'
                }}
              >
                <ImagenDigitalPlatforms src={IconUTPApp} margin="0 0 12px 0" />
                <TitleInterests size="14px" lineHeight="17px">
                  Todo sobre tus clases.
                </TitleInterests>
                <SubTitleInterests>Ingresa desde tu celular.</SubTitleInterests>
              </TitlesInterestsExternal>
            </ContainerInterestsExternal>
          </CardDigitalPlatforms> */}

          <CardDigitalPlatforms
            height="124px"
            width="264px"
            data-testid="cmp-btn-utp+portal"
            onClick={() => {
              handlerRedirectNewPage('https://portal.utp.edu.pe/');
            }}
            formTarget="_blank"
            type="button"
          >
            <ImagenDigitalPlatforms
              src={IconPortal}
              margin="12px 0px 0px -8px"
            />
            <ImagenDigitalPlatforms
              src={IconSmallCrosses}
              margin="-5px 0px 0px -30px"
            />
            <ContainerInterestsExternal>
              <TitlesInterestsExternal
                style={{
                  alignItems: 'baseline',
                  margin: '8px 0px 0px 3px'
                }}
              >
                <ImagenDigitalPlatforms
                  src={IconUTPPOrtal}
                  margin="0 0 12px 0"
                />
                <TitleInterests size="14px" lineHeight="17px">
                  Todas las herramientas, información académica y servicios.
                </TitleInterests>
              </TitlesInterestsExternal>
            </ContainerInterestsExternal>
          </CardDigitalPlatforms>

          <CardDigitalPlatforms
            height="124px"
            width="264px"
            data-testid="cmp-btn-utp+class"
            onClick={() => handlerRedirectNewPage('https://class.utp.edu.pe/')}
            formTarget="_blank"
            type="button"
          >
            <ImagenDigitalPlatforms
              src={IconClass}
              margin="12px 0px 0px -8px"
            />
            <ImagenDigitalPlatforms
              src={IconSmallCrosses}
              margin="-5px 0px 0px -30px"
            />
            <ContainerInterestsExternal>
              <TitlesInterestsExternal
                style={{
                  alignItems: 'baseline',
                  margin: '8px 0px 0px 3px'
                }}
              >
                <ImagenDigitalPlatforms
                  src={IconUTPClass}
                  margin="0 0 12px 0"
                />
                <TitleInterests size="14px" lineHeight="17px">
                  Revisa el contenido de tus clases desde tu computadora o
                  celular.
                </TitleInterests>
              </TitlesInterestsExternal>
            </ContainerInterestsExternal>
          </CardDigitalPlatforms>
        </RowCardDigitalPlatforms>
      </SectionDigitalPlatforms>
    </CenterWrapper>
    <KnowledgeBaseFooterView />
  </div>
);

export default KnowledgeBaseView;
