import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  // View actions
  getProfilePhoto: null,
  // Reducer
  getProfilePhotoRequest: null,
  getProfilePhotoSuccess: null,
  getProfilePhotoFailure: ['error']
});

export { Creators, Types };
