import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Creators as breadcrumbsActions } from '../../../../../domain/useCases/knowledgeBase/breadcrumbUseCase/actions';
import { Creators as registerQualificationActions } from '../../../../../domain/useCases/knowledgeBase/registerCommentArticleUseCase/actions';
import { Creators as articleActions } from '../../../../../domain/useCases/knowledgeBase/getArticleUseCase/actions';
import useFields from './hooks/useFields';
import thumpsDownRed from '../../../../../assets/icons/thumbs-down-red.svg';
import thumpsUpDisabled from '../../../../../assets/icons/thumbs-up-disabled.svg';
import thumpsUpWhite from '../../../../../assets/icons/thumbs_up_white.svg';
import thumpsDownDisabled from '../../../../../assets/icons/thumbs-down-disabled.svg';
import './styles.css';
import ArticleDetailView from './ArticleDetailView';
import XCheckpointManager from '../../../../../core/managers/xcheckpointManager';

const ArticleDetailController = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { push } = useHistory();
  const { articleId }: any = params;

  const { fields, errors, setFieldValue, setFieldError } = useFields();

  const [thumpUpValue, setThumpUpValue] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const refNotBtn = useRef<any>(null);
  const refYesBtn = useRef<any>(null);
  const refImgNot = useRef<any>(null);
  const refNotTitle = useRef<any>(null);
  const refYesTitle = useRef<any>(null);
  const refYesImg = useRef<any>(null);

  const [changingValueSend, TestChangingValueSend] = React.useState(false);
  const [loadStart, setLoadStart] = React.useState(false);

  const { isLoading: isLoadingArticle, data: dataArticle } = useSelector(
    (state: any) => state.articleKnowledgeBase
  );

  const {
    isLoading: isLoadingQualification,
    data: responseQualification,
    isSuccess: statusResponse,
    error: errorResponse,
    again: againData
  } = useSelector((state: any) => state.registerQualification);

  const [isLoadingLocal, setIsLoadingLocal] = useState(true);

  useEffect(() => {
    XCheckpointManager.registerStart('Detalle de artículo');
    dispatch(articleActions.getArticle(articleId));
    setLoadStart(true);
  }, []);

  useEffect(() => {
    if (dataArticle && loadStart) {
      XCheckpointManager.registerEnd('Detalle de artículo');
      setLoadStart(false);
    }
  }, [dataArticle]);

  useEffect(() => {
    if (againData === false && thumpUpValue) {
      setShow(true);
      setThumpUpValue(true);
      refYesBtn.current.className = 'thumps_up selected_yes';
      refYesImg.current.src = thumpsUpWhite;
      refYesTitle.current.className = 'title_si';

      refNotBtn.current.className = 'disabled_btn';
      refImgNot.current.src = thumpsDownDisabled;
      refNotTitle.current.className = 'title_no_disabled';
    }

    if (againData === false && !thumpUpValue) {
      setShow(true);
      setThumpUpValue(false);

      refNotBtn.current.className = 'thumps_down not_selected';
      refImgNot.current.src = thumpsDownRed;
      refNotTitle.current.className = 'title_no';

      refYesBtn.current.className = 'disabled_btn';
      refYesImg.current.src = thumpsUpDisabled;
      refYesTitle.current.className = 'title_no_disabled';
    }
  }, [changingValueSend]);

  useEffect(() => {
    if (dataArticle && dataArticle?.statusCode === 403) {
      push(`/login?redirect=${articleId}`);
    }
    if (dataArticle && dataArticle?.statusCode === 404) {
      push('/');
    }
    if (dataArticle === null) {
      push('/'); // cuando no trae data te lleva al home
    }
    setIsLoadingLocal(false);
    dispatch(breadcrumbsActions.setPosition(3));
  }, [dataArticle]);

  const sendQualification = (qualification: any) => {
    dispatch(registerQualificationActions.registerComment(qualification));
  };

  const clearError = () => {
    dispatch(registerQualificationActions.registerCommentClear());
    setShow(false);
    fields.feedback = '';
  };

  const tryAgainSend = () => {
    dispatch(registerQualificationActions.registerCommentErrorClear());
    TestChangingValueSend(!changingValueSend);
  };

  const handlerClickNo = () => {
    setShow(true);
    setThumpUpValue(false);

    refNotBtn.current.className = 'thumps_down not_selected';
    refImgNot.current.src = thumpsDownRed;
    refNotTitle.current.className = 'title_no';

    refYesBtn.current.className = 'disabled_btn';
    refYesImg.current.src = thumpsUpDisabled;
    refYesTitle.current.className = 'title_no_disabled';
  };

  const handlerClickSi = () => {
    setShow(true);
    setThumpUpValue(true);

    refYesBtn.current.className = 'thumps_up selected_yes';
    refYesImg.current.src = thumpsUpWhite;
    refYesTitle.current.className = 'title_si';

    refNotBtn.current.className = 'disabled_btn';
    refImgNot.current.src = thumpsDownDisabled;
    refNotTitle.current.className = 'title_no_disabled';
  };

  return (
    <ArticleDetailView
      isLoadingArticle={isLoadingArticle || isLoadingLocal || !dataArticle}
      dataArticle={dataArticle}
      isLoadingQualification={isLoadingQualification}
      responseQualification={responseQualification}
      sendQualification={sendQualification}
      statusResponse={statusResponse}
      errorResponse={errorResponse}
      clearError={clearError}
      fields={fields}
      errors={errors}
      setFieldValue={setFieldValue}
      setFieldError={setFieldError}
      thumpUpValue={thumpUpValue}
      show={show}
      tryAgainSend={tryAgainSend}
      refNotBtn={refNotBtn}
      refYesBtn={refYesBtn}
      refImgNot={refImgNot}
      refNotTitle={refNotTitle}
      refYesTitle={refYesTitle}
      refYesImg={refYesImg}
      handlerClickNo={handlerClickNo}
      handlerClickSi={handlerClickSi}
    />
  );
};

export default ArticleDetailController;
