import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '../../../../components';
import IDHelpers from '../../../../../core/helpers/IDHelper';
import { BreadCrumbContainer, BreadCrumbElement } from './styles';
import { Creators as registerQualificationActions } from '../../../../../domain/useCases/knowledgeBase/registerCommentArticleUseCase/actions';

interface BreadCrumbItem {
  name: string;
  position: number;
  path: string;
}

const BreadCrumb = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { data, position } = useSelector((state: any) => state.breadcrumb);

  useEffect(() => {
    dispatch(registerQualificationActions.registerCommentClear());
  }, []);

  const handlerbuttonBack = () => {
    if (data[0].path === '/conoce') {
      history.push('/conoce');
    } else {
      history.push(data[data.length - 2].path);
    }
  };

  return (
    <BreadCrumbContainer data-testid="cmp-breadcrumb">
      <button type="button" className="buttonBack" onClick={handlerbuttonBack}>
        <Icon name="arrow-left" size={16} color="var(--nb-complementary)" />
      </button>
      {data
        ?.filter((item: BreadCrumbItem) => item && item.position < position)
        ?.map((item: any) => (
          <BreadCrumbElement
            className="breadCrumbElement"
            onClick={() => {
              history.push(item.path);
            }}
            key={IDHelpers.generateID()}
          >
            {item.name}
            {item.position < position - 1 && (
              <Icon name="chevron-right" size={13} />
            )}
          </BreadCrumbElement>
        ))}
    </BreadCrumbContainer>
  );
};
export default BreadCrumb;
