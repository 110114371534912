import { put, call, select } from 'redux-saga/effects';
import { Types } from './actions';
import KnowledgeBaseDataRepository from '../../../../data/repositories/KnowledgeBaseRepository';
import KnowledgeBaseRepository from '../../../repositories/KnowledgeBaseRepository';

export default function* saga(actions: any): any {
  try {
    const { categoryCode, subCategoryCode } = actions;
    yield put({ type: Types.GET_ARTICLES_REQUEST });
    const knowledgeBaseRepository: KnowledgeBaseRepository =
      new KnowledgeBaseDataRepository();
    const { data } = yield select((state) => state.profile);
    const articlesResponse = yield call(
      knowledgeBaseRepository.getArticlesByCategory,
      categoryCode,
      subCategoryCode,
      !!data
    );
    if (!articlesResponse) {
      yield put({
        type: Types.GET_ARTICLES_FAILURE,
        error: true
      });
      return;
    }
    yield put({
      type: Types.GET_ARTICLES_SUCCESS,
      data: articlesResponse
    });
  } catch (error) {
    yield put({
      type: Types.GET_ARTICLES_FAILURE,
      error: true
    });
  }
}
