import React from 'react';
import { CcInput, CcTextarea } from '@utp/components-react';

interface Props {
  value?: string;
  label?: string;
  onChange?: (event: any) => void;
  className?: string;
  secondary?: boolean;
  icon?: string;
  disable?: boolean;
  number?: boolean;
  password?: boolean;
  placeholder?: string;
  error?: any;
  textError?: string;
  textArea?: any;
  maxLength?: number;
  autocomplete?: string;
}

function InputText(props: Props) {
  const {
    value,
    onChange,
    label,
    className,
    secondary,
    icon,
    disable,
    number,
    password,
    placeholder,
    error,
    textError,
    textArea,
    maxLength,
    autocomplete
  } = props;
  if (textArea) {
    const { autoGrow } = textArea;
    return (
      <CcTextarea
        label={label}
        value={value}
        className={className}
        color={(secondary && 'secondary') || 'primary'}
        iconName={icon || ''}
        disabled={disable}
        placeholder={placeholder}
        onChangeText={onChange}
        error={error}
        helperText={textError}
        autoGrow={autoGrow}
        maxLength={maxLength}
      />
    );
  }
  return (
    <CcInput
      label={label}
      value={value}
      className={className}
      color={(secondary && 'secondary') || 'primary'}
      iconName={icon || ''}
      disabled={disable}
      type={(number && 'number') || (password && 'password') || 'text'}
      placeholder={placeholder}
      onInput={onChange}
      error={error}
      helperText={textError}
      maxLength={maxLength}
      autocomplete={autocomplete}
    />
  );
}

InputText.defaultProps = {
  value: '',
  label: '',
  onChange: () => {},
  secondary: false,
  className: '',
  icon: '',
  disable: false,
  number: false,
  password: false,
  placeholder: '',
  error: null,
  textError: '',
  textArea: '',
  maxLength: 100,
  autocomplete: ''
};

export default InputText;
