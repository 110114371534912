import { put, select } from 'redux-saga/effects';
import { Types } from './actions';

export default function* saga(): any {
  try {
    const { data: selectedCategory } = yield select(
      (state: any) => state.selectCategory
    );
    const { selectedSubCategory } = yield select(
      (state: any) => state.subCategoryKnowledgeBase
    );
    const { selectedArticle } = yield select(
      (state: any) => state.articlesKnowledgeBase
    );

    const home = {
      name: 'Inicio',
      path: '/',
      position: 0
    };
    const subcategory = selectedCategory
      ? {
          name: selectedCategory.name,
          path: `/subcategoria/${selectedCategory.code}`,
          position: 1
        }
      : null;
    const articles = selectedSubCategory
      ? {
          name: selectedSubCategory.name,
          path: `/articulos`,
          position: 2
        }
      : null;
    const article = selectedArticle
      ? {
          name: selectedArticle.name,
          path: `/articulo`,
          position: 3
        }
      : null;
    const data = [home, subcategory, articles, article]
      .filter((element) => element !== null)
      .map((element, i) => ({ ...element, position: i }));
    yield put({
      type: Types.SET_POSITION_SUCCESS,
      data,
      position: data.length - 1
    });
  } catch (error) {
    yield put({
      type: Types.GET_ARTICLES_FAILURE,
      error: true
    });
  }
}
