import React from 'react';
import {
  Container,
  Text,
  KbFooterContainer,
  KbStoreRow,
  KbAppGoogle,
  KbAppGallery,
  KbFooterColumn,
  KbLinkContainer,
  KbLinkItem,
  KbChannelWhatsapp,
  KbWhatsappPhone,
  KbWhatsappText,
  KbLinkInfo,
  TextLink,
  KbStoreLink,
  KbAllRightsReserved
} from './styled';

import IconGooglePlay from '../../../assets/icons/IconGooglePlayWhite.svg';
import IconAppStore from '../../../assets/icons/IconAppStoreWhite.svg';
import IconAppGallery from '../../../assets/icons/IconAppGalleryWhite.svg';
import IconOutlook from '../../../assets/icons/IconOutlook.svg';
import IconOffice from '../../../assets/icons/IconOffice.svg';
import IconPhone from '../../../assets/icons/IconPhone.svg';
import IconWhatsapp from '../../../assets/icons/IconWhatsapp.svg';

const handlerRedirectNewPage = (url: any) => {
  window.open(url, '_blank');
};

const KnowledgeBaseFooterView = () => (
  <div>
    <Container data-testid="cmp-knowledgefooter-view">
      <div className="gridFooter">
        <div className="footerColumn1_1">
          <KbFooterContainer>
            Encuentra a UTP+app en estas tiendas
          </KbFooterContainer>
          <KbStoreRow>
            <KbAppGoogle
              data-testid="cmp-Kb-google-play-button"
              onClick={() =>
                handlerRedirectNewPage(
                  'https://play.google.com/store/apps/details?id=com.inlearning&hl=es&gl=US'
                )
              }
              formTarget="_blank"
              type="button"
            >
              <img src={IconGooglePlay} alt="IconGooglePlay" />
              <p>Google Play</p>
            </KbAppGoogle>
            <KbAppGoogle
              data-testid="cmp-Kb-app-store-button"
              onClick={() =>
                handlerRedirectNewPage(
                  'https://apps.apple.com/pe/app/utp/id1488014558'
                )
              }
              formTarget="_blank"
              type="button"
            >
              <img src={IconAppStore} alt="IconAppStore" />
              <p>App Store</p>
            </KbAppGoogle>
          </KbStoreRow>
          <div>
            <KbAppGallery
              data-testid="cmp-Kb-app-gallery-button"
              onClick={() =>
                handlerRedirectNewPage(
                  'https://appgallery.huawei.com/#/app/C103466585'
                )
              }
              formTarget="_blank"
              type="button"
            >
              <img src={IconAppGallery} alt="IconAppGallery" />
              <p>AppGallery</p>
            </KbAppGallery>
          </div>
        </div>
        <div className="footerColumn_2">
          <KbFooterColumn>Otras plataformas</KbFooterColumn>
          <KbLinkContainer>
            <img src={IconOutlook} alt="IconOutlook" width="26" height="26" />
            <KbLinkItem
              data-testid="cmp-Kb-outlook-button"
              onClick={() =>
                handlerRedirectNewPage('https://outlook.office.com/')
              }
              formTarget="_blank"
              type="button"
            >
              <Text>Correo outlook</Text>
            </KbLinkItem>
          </KbLinkContainer>
          <KbLinkContainer>
            <img src={IconOffice} alt="IconOffice" width="26" height="26" />
            <KbLinkItem
              data-testid="cmp-Kb-office-button"
              onClick={() =>
                handlerRedirectNewPage(
                  'https://www.microsoft.com/es-es/education/products/office'
                )
              }
              formTarget="_blank"
              type="button"
            >
              <Text>Microsoft Office 365</Text>
            </KbLinkItem>
          </KbLinkContainer>
        </div>
        <div className="footerColumn_3">
          <KbFooterColumn>Contáctanos</KbFooterColumn>

          <KbLinkContainer>
            <img src={IconPhone} alt="IconPhone" width="18" height="18" />
            <KbLinkItem
              data-testid="cmp-Kb-phone-1-button"
              onClick={() => handlerRedirectNewPage('tel:013159600')}
              formTarget="_blank"
              type="button"
            >
              <Text>Lima (01) 315 9600</Text>
            </KbLinkItem>
          </KbLinkContainer>

          <KbLinkContainer>
            <img src={IconPhone} alt="IconPhone" width="18" height="18" />
            <KbLinkItem
              data-testid="cmp-Kb-phone-2-button"
              onClick={() => handlerRedirectNewPage('tel:080119600')}
              formTarget="_blank"
              type="button"
            >
              <Text>Provincias 0801 19 600</Text>
            </KbLinkItem>
          </KbLinkContainer>

          <KbChannelWhatsapp
            data-testid="cmp-Kb-whatsapp-button"
            onClick={() =>
              handlerRedirectNewPage(
                'https://api.whatsapp.com/send?phone=51960252970&text=Hola%2C%20soy%20alumno%20UTP%20y%20deseo%20hacer%20una%20consulta'
              )
            }
            formTarget="_blank"
            type="button"
          >
            <img src={IconWhatsapp} alt="whatsapp" width="24" height="24" />
            <div className="ml_10">
              <KbWhatsappText>Escríbenos en Whatsapp</KbWhatsappText>
              <KbWhatsappPhone>960 252 970</KbWhatsappPhone>
            </div>
          </KbChannelWhatsapp>
        </div>
        <div className="footerColumn_4">
          <KbLinkInfo>Enlaces informativos</KbLinkInfo>
          <KbStoreLink
            className="mb_30"
            data-testid="cmp-Kb-politicas-button"
            onClick={() =>
              handlerRedirectNewPage(
                'https://www.utp.edu.pe/politicas-privacidad?gclid=Cj0KCQjwuMuRBhCJARIsAHXdnqPq0eUdH3u-02coM6mpNsKcD2-993_ooKHIDQtaUGBUQJW_r8bZhN0aAqsOEALw_wcB'
              )
            }
            formTarget="_blank"
            type="button"
          >
            <TextLink>Políticas de privacidad</TextLink>
          </KbStoreLink>
          <KbStoreLink
            data-testid="cmp-Kb-condiciones-button"
            onClick={() =>
              handlerRedirectNewPage(
                'https://www.utp.edu.pe/terminos-condiciones-uso-sitio-web'
              )
            }
            formTarget="_blank"
            type="button"
          >
            <TextLink>Términos y condiciones</TextLink>
          </KbStoreLink>
        </div>
      </div>
    </Container>
    <KbAllRightsReserved>
      Todos los derechos reservados - Universidad Tecnológica del Perú
    </KbAllRightsReserved>
  </div>
);
export default KnowledgeBaseFooterView;
