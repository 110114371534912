import { put } from 'redux-saga/effects';
import { Types } from './actions';
import constants from '../../../core/constants';

function parseJwt2(token: string) {
  return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
}

export default function* execute({ token }: any): any {
  try {
    yield put({ type: Types.GET_PROFILE_REQUEST });
    const tokenParsed = parseJwt2(token);
    const { given_name: firstName } = tokenParsed;

    const isAllowedUser = constants.USERS_CACHE.includes(
      tokenParsed?.email?.toLowerCase()?.replace('@utp.edu.pe', '')
    );
    yield put({
      type: Types.GET_PROFILE_SUCCESS,
      data: {
        firstName,
        isAdm: tokenParsed?.roles?.includes('administrative') && isAllowedUser
      }
    });

    window?.dataLayer.push({ isLogged: 'true' });
  } catch (error) {
    window?.dataLayer.push({ isLogged: 'false' });
    yield put({
      type: Types.GET_PROFILE_FAILURE,
      error: 'Ocurrió un error al momento de leer los datos del estudiante.'
    });
  }
}
