import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  // View actions
  clearCache: null,
  // Reducer
  clearCacheRequest: null,
  clearCacheSuccess: null,
  clearCacheFailure: ['error']
});

export { Creators, Types };
