import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  // View actions
  getArticle: ['articleCode'],
  // Reducer
  getArticleRequest: null,
  getArticleSuccess: null,
  getArticleFailure: ['error'],
  getArticleClear: null
});

export { Creators, Types };
