import { createReducer } from 'reduxsauce';
import { Types } from './actions';
import reducerBase from '../../../base/reducerBase';
import defaultDataCategories from './categories.json';

const { INITIAL_STATE, request, success, failure } = reducerBase();

export default createReducer(INITIAL_STATE, {
  [Types.GET_CATEGORIES_REQUEST]: request,
  [Types.GET_CATEGORIES_SUCCESS]: success,
  [Types.GET_CATEGORIES_FAILURE]: failure
});
