import XCheckpointDataStore from '../../../datastores/XCheckpointDataStore';
import http from './client/axiosHttpClient';
import { HttpMethod } from './client/http.interface';

export default class XCheckpointDataStoreApiRest
  implements XCheckpointDataStore
{
  urlBase = process.env.REACT_APP_XCHECKPOINT_URL || '';

  token = process.env.REACT_APP_XCHECKPOINT_TOKEN || '';

  ipOrigen = process.env.REACT_APP_XCHECKPOINT_IP || '179.6.12.89';

  async writeLog(nameFunc: string, idTransaction: string) {
    try {
      const response = await http
        .getInstance()
        .exchange(`${this.urlBase}/register`, {
          timeout: 1000,
          method: HttpMethod.Post,
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': this.token
          },
          data: {
            codApp: 'A199',
            nameFunc,
            idTransaction,
            timestamp: new Date(),
            ipOrigen: this.ipOrigen
          }
        });
      const result = response.data;
      return result;
    } catch (error) {
      return '';
    }
  }
}
