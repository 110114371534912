import styled from 'styled-components';

const Circle = styled.u`
  background-color: ${(props) => props.color};
  min-height: 6px;
  min-width: 6px;
  border-radius: 50%;
  margin-right: 9px;
  align-self: center;
`;

export default Circle;
