import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  // View actions
  getCategories: null,
  selectedCategory: ['category'],
  // Reducer
  getCategoriesRequest: null,
  getCategoriesSuccess: null,
  getCategoriesFailure: ['error']
});

export { Creators, Types };
