import axios, { AxiosInstance } from 'axios';
import { authManager } from '../../../../../core/managers';
import {
  HttpMethod,
  IHttpClientOptions,
  IHttpResponse
} from './http.interface';

class AxiosService {
  service: AxiosInstance;

  private static instance: AxiosService;

  private constructor() {
    const service = axios.create({
      timeout: 5000
      // headers: {
      //   'content-type': 'application/json'
      // }
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  handleSuccess(response: any) {
    return response;
  }

  handleError = async (error: any) => {
    const { response } = error;
    if (
      ['Invalid refresh token', 'Session not active'].includes(
        response?.data?.error_description
      )
    ) {
      return { data: 'Session not active' };
      await authManager.deleteCredentials();
    }
    return error;
    // no implementado
  };

  public async exchange<T>(
    url: string,
    options: IHttpClientOptions = {}
  ): Promise<IHttpResponse<T>> {
    const exchangeOptions = { ...options };
    exchangeOptions.url = url;
    exchangeOptions.method = options.method || HttpMethod.Get;
    exchangeOptions.data = options.data || {};
    exchangeOptions.headers = options.headers || {};
    exchangeOptions.params = options.params;
    const response = await this.service(exchangeOptions);
    return response.data;
  }

  public static getInstance(): AxiosService {
    if (!AxiosService.instance) {
      AxiosService.instance = new AxiosService();
    }
    return AxiosService.instance;
  }
}

export default AxiosService;
