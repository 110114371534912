import { put, call, select } from 'redux-saga/effects';
import KnowledgeBaseDataRepository from '../../../../data/repositories/KnowledgeBaseRepository';
import KnowledgeBaseRepository from '../../../repositories/KnowledgeBaseRepository';
import defaultDataCategories from '../getCategoriesUseCase/categories.json';
import { Types } from './actions';

export default function* saga({ categoryId }: any): any {
  try {
    yield put({ type: Types.SELECT_CATEGORY_REQUEST });
    const { data: categoryList } = yield select(
      (state) => state.categoryKnowledgeBase
    );
    if (!categoryList) {
      const knowledgeBaseRepository: KnowledgeBaseRepository =
        new KnowledgeBaseDataRepository();
      const categoriesResponse = yield call(
        knowledgeBaseRepository.getCategories
      );
      if (!categoriesResponse) {
        yield put({
          type: Types.GET_CATEGORIES_FAILURE,
          error: true
        });
        return;
      }

      const dataCategories = defaultDataCategories.data
        .map((el: any) =>
          categoriesResponse.find((f: any) => f.code === el.code)
        )
        .filter((c) => !!c);

      const categoryfound = dataCategories.find((c) => c.code === categoryId);
      yield put({
        type: Types.SELECT_CATEGORY_SUCCESS,
        data: categoryfound
      });
    } else {
      const categoryfound = categoryList.find(
        (c: any) => c.code === categoryId
      );
      yield put({
        type: Types.SELECT_CATEGORY_SUCCESS,
        data: categoryfound
      });
    }
  } catch (error: any) {
    yield put({
      type: Types.SELECT_CATEGORY_FAILURE,
      error: true
    });
  }
}
