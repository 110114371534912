import React, { useState, useEffect } from 'react';
import SubcategoryListView from './SubcategoryListView';
import useSubCategory from './useSubCategory';
import XCheckpointManager from '../../../../../core/managers/xcheckpointManager';

const SubcategoryListController = () => {
  const [loadStart, setLoadStart] = useState(false);

  useEffect(() => {
    XCheckpointManager.registerStart('Lista subcategorías');
    setLoadStart(true);
  }, []);

  const {
    subcategories,
    isLoadingSubCategories,
    selectedCategory,
    selectSubCategory
  } = useSubCategory();

  useEffect(() => {
    if (subcategories && loadStart) {
      XCheckpointManager.registerEnd('Lista subcategorías');
      setLoadStart(false);
    }
  }, [subcategories]);

  return (
    <SubcategoryListView
      selectedCategory={selectedCategory}
      subCategories={subcategories}
      isLoading={isLoadingSubCategories}
      selectSubCategory={selectSubCategory}
    />
  );
};
export default SubcategoryListController;
