import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { createGlobalStyle } from 'styled-components';
import keycloak from '../keycloak';

import configureStore from '../domain/base/configureStore';
import RoutesMain from './components/Routes';

const { store, persistor } = configureStore();
const GlobalStyles = createGlobalStyle`
   body.no-scroll {
    overflow-y:hidden !important;
  }
`;

function App() {
  // const eventLogger = (event: unknown, error: unknown) => {
  //   console.log('onKeycloakEvent', event, error);
  // };

  // const tokenLogger = (tokens: unknown) => {
  //   console.log('onKeycloakTokens', tokens);
  // };

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      // onEvent={eventLogger}
      // onTokens={tokenLogger}
      initOptions={{
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`
      }}
    >
      <Provider store={store}>
        <GlobalStyles />
        <PersistGate loading={null} persistor={persistor}>
          <RoutesMain data-testid="cmp-routesmain" />
        </PersistGate>
      </Provider>
    </ReactKeycloakProvider>
  );
}

export default App;
