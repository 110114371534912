import React from 'react';
import { Header } from './components';

interface LayoutHelpProps {
  loginAction: () => void;
  logoutAction: () => void;
  clearCache: () => void;
}

const LayoutHelp = (props: LayoutHelpProps) => (
  <div data-testid="cmp-layout-help" style={{ background: '#363F54' }}>
    <Header {...props} />
  </div>
);

export default LayoutHelp;
