import { call, delay, put } from 'redux-saga/effects';
import { Types } from './actions';
import KnowledgeBaseRepository from '../../repositories/KnowledgeBaseRepository';
import KnowledgeBaseDataRepository from '../../../data/repositories/KnowledgeBaseRepository';

export default function* execute(): any {
  try {
    yield put({ type: Types.CLEAR_CACHE_REQUEST });

    const knowledgeBaseRepository: KnowledgeBaseRepository =
      new KnowledgeBaseDataRepository();
    const clearCacheResponse = yield call(knowledgeBaseRepository.refreshData);
    yield delay(1000);

    yield put({
      type: Types.CLEAR_CACHE_SUCCESS,
      data: { isOk: clearCacheResponse }
    });
  } catch (error) {
    yield put({
      type: Types.CLEAR_CACHE_FAILURE,
      error: 'Ocurrió un error al momento de actualizar la caché'
    });
  }
}
