import React from 'react';
import styled from 'styled-components';
import BrandImage from '../../assets/images/utp-logo.png';

const BrandWrapper = styled.div`
  background: #fff;
  text-align: center;
  color: #000;
  margin-left: 16px;
  height: 100%;
  display: flex;
  align-items: center;
  float: left;
  @media (min-width: 768px) {
    margin-left: 32px;
  }
`;

const BrandLogo = styled.img`
  min-height: 24px;
  max-width: 162px;
  display: inline-block;
  @media (min-width: 768px) {
    min-height: 32px;
    max-width: 210px;
  }
`;

function Brand() {
  return (
    <BrandWrapper data-testid="cmp-brand">
      <BrandLogo alt="UTP+Portal" src={BrandImage} />
    </BrandWrapper>
  );
}

Brand.defaultProps = {};

export default Brand;
