import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  // View actions
  getSubCategories: ['categoryCode'],
  selectSubCategory: ['subcategory'],
  // Reducer
  getSubCategoriesRequest: null,
  getSubCategoriesSuccess: null,
  getSubCategoriesFailure: ['error']
});

export { Creators, Types };
