import { createReducer } from 'reduxsauce';
import { Types } from './actions';

const INITIAL_STATE = {
  isLoading: false,
  error: null
};

const request = (state: any) => ({
  ...state,
  isLoading: true,
  error: null
});

const success = (state: any, action: any) => ({
  ...state,
  isLoading: false,
  data: action.data,
  // period: action.period,
  error: null
});

const failure = (state: any, action: any) => ({
  ...state,
  isLoading: false,
  data: null,
  error: action.error
  // period: null
});

const clear = (state: any) => ({
  ...state,
  data: undefined
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_PROFILE_REQUEST]: request,
  [Types.GET_PROFILE_SUCCESS]: success,
  [Types.GET_PROFILE_FAILURE]: failure,
  [Types.CLEAR_PROFILE]: clear
});
