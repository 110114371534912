import { createReducer } from 'reduxsauce';
import { Types } from './actions';
import reducerBase from '../../../base/reducerBase';

const { success, failure } = reducerBase();
const INITIAL_STATE = {
  isLoading: false,
  data: null,
  error: null
};

const request = (state: any) => ({
  ...state,
  isLoading: true,
  data: null,
  error: null
});

const clear = (state: any) => ({
  ...state,
  isLoading: false,
  isSuccess: false,
  data: null,
  error: null
});

const errorClear = (state: any) => ({
  ...state,
  isLoading: false,
  isSuccess: false,
  error: null,
  again: false
});

export default createReducer(INITIAL_STATE, {
  [Types.REGISTER_COMMENT_REQUEST]: request,
  [Types.REGISTER_COMMENT_SUCCESS]: success,
  [Types.REGISTER_COMMENT_FAILURE]: failure,
  [Types.REGISTER_COMMENT_CLEAR]: clear,
  [Types.REGISTER_COMMENT_ERROR_CLEAR]: errorClear
});
