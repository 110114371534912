import React from 'react';
import { Icon } from '../../../../../../components';
// import { Icon } from '../../components';

const InputField = ({
  value,
  setValue,
  placeholder,
  showCount,
  maxLength,
  error,
  errorText,
  name,
  className
}: any) => (
  <>
    <textarea
      style={{ width: '100%' }}
      className={`${value ? 'inputFilled' : ''} ${
        maxLength < value.length ? 'inputError' : ''
      } ${className}`}
      value={value}
      onChange={(e) => {
        setValue({ name, value: e.target.value });
      }}
      rows={5}
      placeholder={placeholder}
    />
    {showCount && (
      <div
        className="inputFooterv2"
        style={{
          marginTop: 4,
          justifyContent: error ? 'space-between' : 'flex-end',
          display: 'flex',
          fontSize: 12,
          color: maxLength < value.length ? '#E8212B' : '#4F6168'
        }}
      >
        {error && (
          <div className="errorText">
            <Icon name="alert-triangle" size={12} />
            {errorText}
          </div>
        )}
        {value.length} / {maxLength}
      </div>
    )}
  </>
);

export default InputField;
