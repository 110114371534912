import React from 'react';
import { CcSingleSelectInput } from '@utp/components-react';

interface Props {
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  label?: string;
  loader?: boolean;
  autocomplete?: boolean;
  IconRotate?: boolean;
  iconName?: string;
  helperText?: string;
  choices?: Array<any>;
}

const InputSelect = (props: Props) => {
  const {
    placeholder,
    disabled,
    error,
    label,
    loader,
    autocomplete,
    IconRotate,
    iconName,
    helperText,
    choices
  } = props;

  return (
    <CcSingleSelectInput
      choices={choices || InputSelect.defaultProps.choices}
      placeholder={placeholder || InputSelect.defaultProps.placeholder}
      disabled={disabled || InputSelect.defaultProps.disabled}
      error={error || InputSelect.defaultProps.error}
      label={label || InputSelect.defaultProps.label}
      loader={loader || InputSelect.defaultProps.loader}
      autocomplete={autocomplete || InputSelect.defaultProps.autocomplete}
      IconRotate={IconRotate || InputSelect.defaultProps.IconRotate}
      iconName={iconName || InputSelect.defaultProps.iconName}
      helperText={helperText || InputSelect.defaultProps.helperText}
    />
  );
};

InputSelect.defaultProps = {
  placeholder: '',
  disabled: false,
  error: false,
  label: '',
  loader: false,
  autocomplete: false,
  IconRotate: false,
  iconName: 'chevron-down',
  helperText: 'Incorrect entry',
  choices: []
};

export default InputSelect;
