import React, { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { CcIcon, CcLoader } from '@utp/components-react';
import Block from '../Block';
import Label from '../Label';
import IDHelpers from '../../../core/helpers/IDHelper';
import { SelectElement, SelectWrapper } from './styles';
import { ISelect } from './Interfaces';
import { Filterable } from './filterable';

function Select(props: ISelect) {
  // const getWeeek = (id: number, data: any) => {
  //   let week = {};
  //   for (let index = 0; index <= data.length; index += 1) {
  //     const element = data[index];
  //     if (index === id - 1) {
  //       week = element;
  //     }
  //   }

  //   return week;
  // };

  const {
    options,
    handleChangeSelect,
    isRequired,
    placeholder,
    isDisabled,
    isFilterable,
    maxOptionsToFilter,
    minCharactersToFilter,
    isLoading
  } = props;

  const [openmenu, setOpenmenu] = useState(false);
  const [currentValue, setCurrentValue]: any = useState<any>(null);
  const [filterOptions, setFilterOptions]: any = useState<any>([]);

  useEffect(() => {
    setFilterOptions(options);
  }, []);

  useEffect(() => {
    // if (isLoading && !currentValue) {
    if (isLoading) {
      setCurrentValue(null);
      setFilterOptions(options);
    }
  }, [isLoading, options]);

  const menuToggle = () => {
    setOpenmenu(!openmenu);
  };

  const updateLabel = (item: any) => {
    // handleUpdateDropdown(getWeeek(ind + 1, data));
    setCurrentValue(item);
    handleChangeSelect(item);
    setOpenmenu(false);
    // getValue(item);
  };
  const KEY_CODES = Object.freeze({
    RETURN: 13,
    ESC: 27,
    SPACE: 32,
    END: 35,
    HOME: 36,
    UP: 38,
    DOWN: 40
  });

  const buttonHandleKeyEvents = (event: any) => {
    event.preventDefault();

    switch (event.keyCode) {
      case KEY_CODES.SPACE:
      case KEY_CODES.RETURN:
      case KEY_CODES.DOWN:
        setOpenmenu(true);
        break;
      case KEY_CODES.UP:
        setOpenmenu(true);
        break;
      default:
        setOpenmenu(false);
        break;
    }
  };

  const menuHandleKeyEvents = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    switch (event.keyCode) {
      case KEY_CODES.SPACE:
      case KEY_CODES.DOWN:
        // focusNextItem();
        break;
      case KEY_CODES.UP:
        // focusPrevItem();
        break;
      case KEY_CODES.ESC:
        setOpenmenu(false);
        break;
      default:
        break;
    }
  };
  const menuItemsHandleKeyEvents = (event: any) => {
    if (
      event.keyCode === KEY_CODES.SPACE ||
      event.keyCode === KEY_CODES.RETURN
    ) {
      // for items we just need to stop event propagation or the menu container
      // will handle these event(which we don't want)
      event.stopPropagation();
      // return;
    }
  };

  const UpdateFilterOptions = (filter: any) => {
    const newFilteredOptions = [];
    if (filter.length >= minCharactersToFilter) {
      for (let index = 0; index < options?.length; index += 1) {
        const itemOption: any = {
          value: options[index].value,
          label: options[index].label
        };
        const lbl = options[index].label.toLowerCase();
        if (lbl.includes(filter.toLowerCase())) {
          newFilteredOptions.push(itemOption);
        }
      }
      if (newFilteredOptions.length > 0) {
        setFilterOptions(newFilteredOptions);
      }
    } else {
      setFilterOptions(options);
    }
  };

  return (
    <SelectElement data-testid="cmp-select-field">
      <OutsideClickHandler
        onOutsideClick={() => {
          setOpenmenu(false);
          if (currentValue !== null && isRequired) {
            // onValidate();
          } else {
            // onError();
          }
        }}
      >
        <SelectWrapper className={isDisabled ? 'disabled' : ''}>
          <Block
            className={`selected ${openmenu ? 'open' : ''} ${
              currentValue ? 'filled' : ''
            }`}
            onClick={() => {
              if (!isDisabled) {
                menuToggle();
              }
            }}
            role="button"
            tabIndex={0}
            onKeyDown={buttonHandleKeyEvents}
          >
            <Label
              size={14}
              text={
                currentValue === null || isLoading
                  ? placeholder
                  : currentValue?.label
              }
              color={currentValue === null ? '#4f6168' : '#161d1f'}
            />
            {isLoading && <CcLoader size={25} color="#5B36F2" />}
            {openmenu && !isLoading && <CcIcon name="chevron-up" />}

            {!openmenu && !isLoading && <CcIcon name="chevron-down" />}
          </Block>
          <Block
            className={`menu ${openmenu ? 'active' : ''}`}
            aria-expanded={openmenu}
            // onKeyDown={menuHandleKeyEvents}
          >
            <Block className="menu-wrapper">
              {!isLoading &&
                isFilterable &&
                options.length > maxOptionsToFilter && (
                  <Filterable
                    valueSelected={currentValue}
                    onChange={UpdateFilterOptions}
                    options={options}
                  />
                )}
              {filterOptions &&
              filterOptions.length > 0 &&
              isFilterable &&
              !isLoading ? (
                <ul
                  className="menu-container"
                  role="menu"
                  onKeyDown={menuHandleKeyEvents}
                >
                  {filterOptions.map((item: any) => (
                    <li
                      key={IDHelpers.generateID()}
                      className={`item ${
                        item.value === currentValue?.value ? 'active' : ''
                      }`}
                    >
                      <Block
                        className="item-link"
                        onClick={() => updateLabel(item)}
                        onKeyDown={menuItemsHandleKeyEvents}
                        tabIndex={0}
                        role="menuitem"
                      >
                        {item.label}
                      </Block>
                    </li>
                  ))}
                </ul>
              ) : (
                <ul
                  className="menu-container"
                  role="menu"
                  onKeyDown={menuHandleKeyEvents}
                >
                  {options.map((item: any) => (
                    <li
                      key={IDHelpers.generateID()}
                      className={`item ${
                        item.value === currentValue?.value ? 'active' : ''
                      }`}
                    >
                      <Block
                        className="item-link"
                        onClick={() => updateLabel(item)}
                        onKeyDown={menuItemsHandleKeyEvents}
                        tabIndex={0}
                        role="menuitem"
                      >
                        {item.label}
                      </Block>
                    </li>
                  ))}
                </ul>
              )}
              {/* {options && options.length > 0 && !isFilterable && !isLoading && (
                <ul
                  className="menu-container"
                  role="menu"
                  onKeyDown={menuHandleKeyEvents}
                >
                  {options.map((item: any) => (
                    <li
                      key={IDHelpers.generateID()}
                      className={`item ${
                        item.value === currentValue?.value ? 'active' : ''
                      }`}
                    >
                      <Block
                        className="item-link"
                        onClick={() => updateLabel(item)}
                        onKeyDown={menuItemsHandleKeyEvents}
                        tabIndex={0}
                        role="menuitem"
                      >
                        {item.label}
                      </Block>
                    </li>
                  ))}
                </ul>
              )} */}
            </Block>
          </Block>
        </SelectWrapper>
      </OutsideClickHandler>
    </SelectElement>
  );
}

Select.defaultProps = {
  options: [],
  placeholder: '',
  value: '',
  handleChangeSelect: () => {},
  getValue: () => {},
  onValidate: () => {},
  onError: () => {},
  isRequired: false,
  isDisabled: false,
  isFilterable: false,
  maxOptionsToFilter: 15,
  minCharactersToFilter: 3,
  isLoading: false
};

export default Select;
