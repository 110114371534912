import KnowledgeBaseDataStore from '../../../datastores/KnowledgeBaseDataStore';
import { http } from './client2/http';

export default class KnowledgeBaseDataStoreApiRest
  implements KnowledgeBaseDataStore
{
  async getCategories() {
    try {
      const response = await http.get('/ConsultaCatalogoCategoria');
      const { knowledgeBase } = response.data?.data;
      return knowledgeBase.categories;
    } catch (error) {
      throw new Error('error');
    }
  }

  async getSubCategories(categoryCode: string, isAuthenticated: boolean) {
    try {
      const response = await http.get(
        `/ConsultaCatalogoSubCategoria?t=${+isAuthenticated}&categoryCode=${categoryCode}`
      );
      const { knowledgeBase } = response.data?.data;
      return knowledgeBase.subCategories;
    } catch (error) {
      throw new Error('error');
    }
  }

  async getMetadata(isAuthenticated: boolean) {
    try {
      const response = await http.get(
        `/ListarTitulosArticulos?t=${+isAuthenticated}`
      );
      const { knowledgeBase } = response.data?.data;
      return knowledgeBase.metadata;
    } catch (error) {
      throw new Error('error');
    }
  }

  async getArticlesByCategory(
    categoryCode: string,
    subCategoryCode: string,
    isAuthenticated: boolean
  ) {
    try {
      const response = await http.get(
        `/ListarArticulos?t=${+isAuthenticated}&categoryCode=${categoryCode}&subCategoryCode=${subCategoryCode}`
      );
      const { knowledgeBase } = response.data?.data;
      return knowledgeBase.articles;
    } catch (error) {
      throw new Error('error');
    }
  }

  async getArticle(articleCode: string) {
    try {
      const response = await http.get(
        `/ConsultaArticulo?articleCode=${articleCode}`
      );
      const { statusCode } = response?.data;
      const { knowledgeBase } = response.data?.data;
      return { ...knowledgeBase?.article, statusCode };
    } catch (error) {
      throw new Error('error');
    }
  }

  async sendFeedback(
    articleCode: string,
    thumbsUp: boolean,
    feedback: string,
    isAuthenticated: boolean
  ) {
    try {
      const data = {
        articleCode,
        thumbsUp,
        feedback,
        t: +isAuthenticated
      };
      const response = await http.post<any>('/RegistraComentario', data);
      return response?.data?.data?.sendFeedback;
    } catch (error) {
      throw new Error('error');
    }
  }

  async refreshData() {
    try {
      const response = await http.post<any>('/refreshData');
      return response?.data;
    } catch (error) {
      throw new Error('error');
    }
  }
}
