import { createReducer } from 'reduxsauce';
import { Types } from './actions';

const INITIAL_STATE = {
  position: 0
};

const success = (state: any, action: any) => ({
  ...state,
  data: action.data,
  position: action.position
});

export default createReducer(INITIAL_STATE, {
  [Types.SET_POSITION_SUCCESS]: success
});
