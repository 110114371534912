import * as React from 'react';
import { CcLoader } from '@utp/components-react';
import LoaderElement from './styles';

interface SpinnerProps {
  size?: number;
  height?: number;
  colorText?: string;
}

const Spinner: React.FC<SpinnerProps> = (props) => {
  const { size, height, colorText } = props;
  return (
    <LoaderElement height={`${height}`}>
      <div className="loadingName" style={{ color: colorText }}>
        Cargando...
      </div>
      <div className="spinnerCircle">
        <CcLoader size={size} color="#5B36F2" />
      </div>
    </LoaderElement>
  );
};

Spinner.defaultProps = {
  size: 25,
  height: 300,
  colorText: '#5B36F2'
};

export default Spinner;
