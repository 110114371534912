const routes = {
  error: '/error',
  logout: '/logout',
  help: {
    home: '/conoce',
    category: '/categoria',
    subcategory: '/subcategoria/:categoryId',
    articles: '/articulos',
    article: '/articulo/:articleId'
  }
};

export default routes;
