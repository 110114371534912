import React from 'react';
import { CcIcon } from '@utp/components-react';
import styled from 'styled-components';

export interface IIcon {
  border: string;
  borderRadius: string;
  padding: string;
  backgroundColor: string;
  shadow: string;
  maxWidth: string;
  minWidth: string;
  margin: string;
  display: string;
  flexDirection: string;
  alignItems: string;
  alignContent: string;
  justifyContent: string;
  height: string;
  rotationDeg: string;
}

const StyledIcon = styled.div.attrs((props: IIcon) => ({
  borderRadius: props.borderRadius,
  padding: props.padding,
  backgroundColor: props.backgroundColor,
  shadow: props.shadow,
  maxWidth: props.maxWidth,
  minWidth: props.minWidth,
  margin: props.margin,
  display: props.display,
  flexDirection: props.flexDirection,
  alignItems: props.alignItems,
  alignContent: props.alignContent,
  justifyContent: props.justifyContent,
  border: props.border,
  height: props.height,
  rotationDeg: props.rotationDeg
}))`
  width: 100%;
  margin: ${(props) => (props.margin ? props.margin : 'initial')};
  border: ${(props) => (props.border ? props.border : 'none')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : 'none'};
  padding: ${(props) => (props.padding ? props.padding : 'none')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'auto')};
  min-width: ${(props) => (props.minWidth ? props.minWidth : 'auto')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : 'transparent'};
  box-shadow: ${(props) => (props.shadow ? props.shadow : 'none')};
  display: ${(props) => (props.display ? props.display : 'flex')};
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : 'column'};
  align-items: ${(props) =>
    props.alignItems ? props.alignItems : 'flex-start'};
  align-content: ${(props) =>
    props.alignContent ? props.alignContent : 'flex-start'};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  img {
    transition: all 0.5s ease;
    transform: rotate(${(props: any) => props.rotationDeg}deg);
  }

  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
`;

const Icon = (props: any) => {
  const { name, size, color, customized, children } = props;
  if (customized) {
    return <StyledIcon {...props}>{children}</StyledIcon>;
  }
  return (
    <CcIcon
      name={name}
      size={size}
      style={{ color }}
      data-testid="cmp-cc-icon"
    />
  );
};

export default Icon;
