import React from 'react';
import styled from 'styled-components';

interface Props {
  value?: string;
}
const UserInfoWrapper = styled.div`
  text-align: center;
  color: var(--neutral-03);
  padding: 28px 0px;
  display: block;
  float: left;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  color: var(--neutral-03);
  text-transform: uppercase;
`;

function UserInfo(props: Props) {
  const { value } = props;
  return <UserInfoWrapper>{value}</UserInfoWrapper>;
}

UserInfo.defaultProps = {
  value: ''
};

export default UserInfo;
