import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  // View actions
  getMetadata: null,
  // Reducer
  getMetadataRequest: null,
  getMetadataSuccess: null,
  getMetadataFailure: ['error']
});

export { Creators, Types };
