import { isMobile } from 'react-device-detect';
import photo from './photo.json';

const API_REST_URL = process.env.REACT_APP_ENV_URL_BACKEND;
const DATASOURCE_ENV = process.env.REACT_APP_DATASOURCE_ENV;
const AUTH_TOKEN = 'AUTH_TOKEN';
const REFRESH_TOKEN = 'REFRESH_TOKEN';
const GRAPHQL_URL =
  process.env.REACT_APP_GRAPHQL_URL ||
  'https://api-qa.utpxpedition.com/graphql';
const MIL_PERU = 5 * 1000 * 60 * 60;
const CAREER = { PREG: 'PREG', CGT: 'PPE' };
const URL_MAPS = 'https://maps.google.com/maps?q=';
const PORTAL_ESTUDIANTE = 'https://portalestudiante02.utp.edu.pe/';

const CLASS_TYPE = {
  faceToFaceTheoretical: '1',
  faceToFacePractical: '2',
  virtualTheoretical: '3',
  virtualPractical: '4',
  remoteTheoretical: '5',
  remotePractical: '6'
};

const CARD_TYPES = {
  CURSO_REMOTO: 'C',
  CURSO_PRESENCIAL: 'P',
  CURSO_VIRTUAL: 'V',
  ACTIVIDAD: 'A',
  EXAMEN: 'E',
  REFORZAMIENTO: 'R'
};

const INSTRUCTION_MODES = {
  REMOTO: 'R',
  PRESENCIAL: 'P',
  SEMI_PRESENCIAL: 'SP',
  VIRTUAL: 'VT'
};

const SCREEN_SIZE = {
  sm: 640,
  md: 768,
  lg: 992,
  xl: 1280
};

const PIXELS_PER_MIN_DSK = 1.3333;
const PIXELS_PER_MIN_MOB = 1.6666;

const BREAKPOINT_SIZE = SCREEN_SIZE.lg;
const keycloakConfig = {
  realm: process.env.REACT_APP_KEYCLOAK_REALM || 'Xpedition',
  url: process.env.REACT_APP_KEYCLOAK_URL,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || 'utpmas-info-web'
};

export default {
  API_REST_URL: `${API_REST_URL}`,
  GRAPHQL_URL,
  AUTH_TOKEN,
  REFRESH_TOKEN,
  MIL_PERU,
  CAREER,
  CLASS_TYPE,
  DATASOURCE_ENV,
  SCREEN_SIZE,
  BREAKPOINT_SIZE,
  PIXELS_PER_MIN: isMobile ? PIXELS_PER_MIN_MOB : PIXELS_PER_MIN_DSK,
  CARD_TYPES,
  INSTRUCTION_MODES,
  URL_MAPS,
  DEFAULT_PHOTO: photo.photo,
  PORTAL_ESTUDIANTE,
  KEYCLOAK: keycloakConfig,
  URL_PORTAL: process.env.REACT_APP_URL_PORTAL || 'https://portal.utp.edu.pe',
  USERS_CACHE: ['lbulnes', 'mlazo', 'agonzales', 'amanriquen']
};
