import React from 'react';
import InputText from '../InputText';
import { SelectFilterable } from './styles';

const Filterable = (props: any) => {
  const { onChange } = props;
  return (
    <>
      <SelectFilterable>
        <InputText
          // value={inputTextToFilter || valueSelected?.label}
          // label={inputTextToFilter || valueSelected?.label}
          icon="search"
          placeholder="Buscar"
          onChange={(event: any) => {
            onChange(event.target.value);
          }}
        />
      </SelectFilterable>
    </>
  );
};

export { Filterable };
