import { takeLatest } from 'redux-saga/effects';

import {
  getProfileUseCase,
  getCategoriesKnowledgeBaseUseCase,
  getMetadataKnowledgeBaseUseCase,
  getArticlesKnowledgeBaseUseCase,
  getArticleDetailKnowledgeBaseUseCase,
  subcategoryUseCase,
  getArticleKnowledgeBaseUseCase,
  registerCommentArticleKnowledgeBaseUseCase,
  breadcrumbUseCase,
  getProfilePhotoUseCase,
  clearCacheUseCase,
  selectCategoryUseCase
} from '../useCases';

export default function* rootSaga() {
  yield takeLatest(getProfileUseCase.Types.GET_PROFILE, getProfileUseCase.saga);
  yield takeLatest(
    getProfilePhotoUseCase.Types.GET_PROFILE_PHOTO,
    getProfilePhotoUseCase.saga
  );

  yield takeLatest(
    getCategoriesKnowledgeBaseUseCase.Types.GET_CATEGORIES,
    getCategoriesKnowledgeBaseUseCase.saga
  );
  yield takeLatest(
    getMetadataKnowledgeBaseUseCase.Types.GET_METADATA,
    getMetadataKnowledgeBaseUseCase.saga
  );
  yield takeLatest(
    getArticlesKnowledgeBaseUseCase.Types.GET_ARTICLES,
    getArticlesKnowledgeBaseUseCase.saga
  );
  yield takeLatest(
    getArticleDetailKnowledgeBaseUseCase.Types.GET_ARTICLE_DETAIL,
    getArticleDetailKnowledgeBaseUseCase.saga
  );
  yield takeLatest(
    subcategoryUseCase.Types.GET_SUB_CATEGORIES,
    subcategoryUseCase.saga
  );
  yield takeLatest(
    getArticleKnowledgeBaseUseCase.Types.GET_ARTICLE,
    getArticleKnowledgeBaseUseCase.saga
  );
  yield takeLatest(
    registerCommentArticleKnowledgeBaseUseCase.Types.REGISTER_COMMENT,
    registerCommentArticleKnowledgeBaseUseCase.saga
  );
  yield takeLatest(
    breadcrumbUseCase.Types.SET_POSITION,
    breadcrumbUseCase.saga
  );
  yield takeLatest(clearCacheUseCase.Types.CLEAR_CACHE, clearCacheUseCase.saga);
  yield takeLatest(
    selectCategoryUseCase.Types.SELECT_CATEGORY,
    selectCategoryUseCase.saga
  );
}
