import { put, call } from 'redux-saga/effects';
import { Types } from './actions';
import KnowledgeBaseDataRepository from '../../../../data/repositories/KnowledgeBaseRepository';
import KnowledgeBaseRepository from '../../../repositories/KnowledgeBaseRepository';

export default function* saga(actions: any): any {
  try {
    const { articleCode } = actions;
    yield put({ type: Types.GET_ARTICLE_REQUEST });
    const knowledgeBaseRepository: KnowledgeBaseRepository =
      new KnowledgeBaseDataRepository();
    const articlesResponse = yield call(
      knowledgeBaseRepository.getArticle,
      articleCode
    );
    // console.log('saga get article-->', articlesResponse);

    if (!articlesResponse) {
      yield put({
        type: Types.GET_ARTICLE_FAILURE,
        error: true
      });
      return;
    }
    yield put({
      type: Types.GET_ARTICLE_SUCCESS,
      data: articlesResponse
    });
  } catch (error) {
    yield put({
      type: Types.GET_ARTICLE_FAILURE,
      error: true
    });
  }
}
