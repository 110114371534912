import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  background: #ffffff;
  padding: 40px 16px;
  // border-top: 1px solid #a8cbe2;
  

  .mb_30 {
    margin-bottom: 30px;
  }

  .gridFooter {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 16px;
    display: grid;
    width: 100%;
  }

  .footerColumn1_1 {
    grid-column: span 2 / span 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footerColumn_2 {
    grid-column: span 2 / span 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #d5e5f0;
    padding-top: 24px;
  }

  .footerColumn_3 {
    grid-column: span 2 / span 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #d5e5f0;
    padding-top: 24px;
    padding-bottom: 12px;
  }

  .footerColumn_4 {
    grid-column: span 2 / span 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #d5e5f0;
    padding-top: 24px;
  }

  @media (min-width: 768px) {
    padding: 0px 24px;
    border-top: 0;

    .gridFooter {
      grid-template-columns: repeat(6, minmax(0, 1fr));
      gap: 24px;
      border-top: 1px solid #a8cbe2;
      border-bottom: 1px solid #a8cbe2;
      padding-top: 40px;
      padding-bottom: 40px;
      width: 720px;
    }
    .footerColumn1_1 {
      grid-column: span 3 / span 3;
    }

    .footerColumn_2 {
      grid-column: span 3 / span 3;
      border-top: 0;
      padding-top: 0;
    }

    .footerColumn_3 {
      grid-column: span 3 / span 3;
      border-top: 0;
      padding-top: 0;
    }

    .footerColumn_4 {
      grid-column: span 3 / span 3;
      border-top: 0;
      padding-top: 0;
    }
  }

  @media (min-width: 992px) {
    padding: 0px 32px;
    border-top: 0;
    .gridFooter {
      grid-template-columns: repeat(12, minmax(0, 1fr));
      gap: 32px;
      border-top: 1px solid #a8cbe2;
      border-bottom: 1px solid #a8cbe2;
      width: 928px;
    }

    .footerColumn1_1 {
      grid-column: span 3 / span 3;
      align-items: flex-start;
    }

    .footerColumn_2 {
      grid-column: span 3 / span 3;
      align-items: flex-start;
    }

    .footerColumn_3 {
      grid-column: span 3 / span 3;
      align-items: flex-start;
    }

    .footerColumn_4 {
      grid-column: span 3 / span 3;
      align-items: flex-start;
    }
  }

  @media (min-width: 1280px) {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0px 80px;
    border-top: 0;

    .gridFooter {
      grid-template-columns: repeat(13, minmax(0, 1fr));
      gap: 12px;
      border-top: 1px solid #a8cbe2;
      border-bottom: 1px solid #a8cbe2;
      padding-top: 40px;
      width: 1120px;
    }
    .footerColumn1_1 {
      grid-column: span 5 / span 5;
    }

    .footerColumn_2 {
      grid-column: span 3 / span 3;
    }

    .footerColumn_3 {
      grid-column: span 3 / span 3;
    }

    .footerColumn_4 {
      grid-column: span 2 / span 2;
    }
  }

  // @media (min-width: 1920px) {
  //   padding-top: 40px;
  //   border-top: 1px solid #a8cbe2;

  //   .gridFooter {
  //     border-top: 0;
  //     padding-top: 0;
  //   }
  }
`;

const KbFooterContainer = styled.p`
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #161d1f;
  padding-bottom: 16px;

  @media (min-width: 768px) {
    padding-bottom: 38px;
  }
`;

const KbTextUtp = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  padding-bottom: 22px;
  color: #4f6168;
  margin: 6px 10px 5px 2px;
  display: flex;
  flex-direction: column;

  @media (min-width: 992px) {
    align-items: flex-start;
  }
  @media (min-width: 1280px) {
    flex-direction: row;
  }
`;

const KbWebUtp = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #5b36f2;

  border-bottom: 1px solid #5b36f2;
  margin: 6px 5px 5px 1px;
`;

const KbWebUrl = styled.button`
  font-size: 14px;
  font-weight: 400;
  color: #5b36f2;
  margin-top: 16px;
  text-decoration: underline;
  text-underline-offset: 5px;

  &:hover {
    color: #745ef6;
    cursor: pointer;
    font-weight: 900;
  }

  &:focus {
    padding: 0px 2px 4px 2px;
    color: #745ef6;
    border: 1px solid #745ef6;
    border-radius: 4px;
  }

  &:active {
    color: #745ef6;
  }

  @media (min-width: 1280px) {
    margin-top: 0px;
  }
`;
const KbStoreRow = styled.div`
  img {
    margin-right: 7.5px;
  }
  @media (min-width: 1280px) {
    display: flex;
  }
`;

const KbAppGoogle = styled.button`
  display: flex;
  margin-bottom: 16px;
  margin-right: auto;
  margin-left: auto;

  background: #000f37;
  border-radius: 4px;
  color: #fff;
  align-items: center;
  padding: 4px 8px 4px 8px;

  &:hover {
    background: #001d58;
    cursor: Pointer;
  }

  &:focus {
    background: #002e84;
  }

  &:active {
    background: #002e84;
  }
  @media (min-width: 768px) {
    margin-right: 0px;
    margin-left: 0px;
  }
  @media (min-width: 1280px) {
    margin-right: 16px;
  }
`;

const KbAppGallery = styled.button`
  display: flex;
  background: #000f37;
  border-radius: 4px;
  color: #fff;
  align-items: center;
  padding: 4px 8px 4px 8px;
  margin-bottom: 36px;

  img {
    margin-right: 7.5px;
  }

  &:hover {
    background: #001d58;
    cursor: Pointer;
  }

  &:focus {
    background: #002e84;
  }
  &:active {
    background: #002e84;
  }
`;

const KbFooterColumn = styled.p`
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #161d1f;
  padding-bottom: 16px;

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-around;
    padding-bottom: 40px;
  }
`;

const KbLinkContainer = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 32px;
  align-items: center;
  color: #161d1f;

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;

const PlaneText = styled.div`
  margin-right: 10px;
`;

const Text = styled.p`
  color: #161d1f;
  display: flex;
  align-items: center;

  font-size: 14px;
  line-height: 20px;
  border-bottom: 1px solid;
  margin: 6px 1px 5px 1px;
`;

const KbLinkItem = styled.button`
  margin-left: 13px;

  &:hover {
    color: #161d1f;
    cursor: pointer;
    font-weight: 900;
  }
  &:focus {
    color: #161d1f;
    border: 1px solid #161d1f;
    border-radius: 4px;
  }

  &:active {
    color: #161d1f;
    font-weight: 400;
  }
`;

const KbLinkContact = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  align-items: center;
  margin-bottom: 32px;
  color: #161d1f;
  border-bottom: 1px solid #161d1f;
`;

const KbChannelWhatsapp = styled.button`
  display: flex;
  color: #226c37;
  border: 1px solid #226c37;
  border-radius: 4px;
  padding: 4px 8px;
  align-items: center;

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
  }

  .ml_10 {
    margin-left: 10px;
  }

  &:hover {
    color: #2d8746;
    cursor: Pointer;
    border: 1px solid #2d8746;
    border-radius: 4px;
  }

  &:focus {
    color: #175128;
  }

  &:active {
    color: #175128;
  }
`;

const KbWhatsappText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  align-items: center;
  color: #226c37;
`;

const KbWhatsappPhone = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  align-items: left;
  color: #226c37;
`;

const KbLinkInfo = styled.p`
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #161d1f;
  padding-bottom: 16px;

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    padding-bottom: 38px;
  }
`;

const TextLink = styled.p`
  color: #5b36f2;
  margin: 6px 2px 5px 2px;
  font-size: 14px;
  line-height: 20px;
  border-bottom: 1px solid;
  display: flex;
  justify-content: center;

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;

const KbStoreLink = styled.button`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  align-items: center;

  color: #5b36f2;

  &:hover {
    color: #745ef6;
    cursor: pointer;
    font-weight: 900;
  }

  &:focus {
    color: #745ef6;
    border: 1px solid #745ef6;
    border-radius: 4px;
  }

  &:active {
    color: #745ef6;
    font-weight: 400;
  }
`;

const KbAllRightsReserved = styled.div`
  border-top: 1px solid #a8cbe2;
  background: #ffffff;
  padding: 16px;
  text-align: center;

  @media (min-width: 768px) {
    border-top: 0;
  }

  @media (min-width: 992px) {
    border-top: 0;
  }

  @media (min-width: 1280px) {
    border-top: 0;
  }

  // @media (min-width: 1920px) {
  //   border-top: 1px solid #a8cbe2;
  //   padding-top: 0px;
  // }
`;

export {
  Container,
  KbFooterContainer,
  KbLinkItem,
  KbStoreRow,
  KbTextUtp,
  KbWebUtp,
  KbWebUrl,
  KbAppGoogle,
  KbAppGallery,
  KbFooterColumn,
  PlaneText,
  Text,
  KbLinkContainer,
  KbLinkContact,
  KbChannelWhatsapp,
  KbWhatsappPhone,
  KbWhatsappText,
  KbLinkInfo,
  TextLink,
  KbStoreLink,
  KbAllRightsReserved
};
