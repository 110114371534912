import React from 'react';
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import * as screens from '../../modules';
import { Creators as ProfileActions } from '../../../domain/useCases/getProfile';
import { Creators as CacheActions } from '../../../domain/useCases/clearCache';
import routes from './routingMap';
import LayoutHelp from '../LayoutHelp';

const Main = () => {
  const dispatch = useDispatch();
  const { keycloak, initialized }: any = useKeycloak();

  if (!initialized) {
    return <screens.Splash />;
  }
  if (keycloak) {
    dispatch(ProfileActions.getProfile(keycloak?.token));
  } else {
    dispatch(ProfileActions.clearProfile());
  }

  const logActions = {
    loginAction: () => {
      keycloak.login();
    },
    logoutAction: () => {
      keycloak.logout();
    },
    clearCache: () => {
      dispatch(CacheActions.clearCache());
    }
  };

  return (
    <BrowserRouter>
      <LayoutHelp {...logActions} />
      <Switch>
        <Route exact path="/" component={screens.KnowledgeBaseController} />
        <Route
          exact
          path={routes.help.home}
          component={screens.KnowledgeBaseController}
        />
        {/* <Route
          exact
          path="/conoce"
          component={screens.KnowledgeBaseController}
        /> */}
        <Route
          exact
          path={routes.help.category}
          component={screens.KnowledgeBaseController}
        />
        <Route
          exact
          path={routes.help.articles}
          component={screens.ArticleListController}
        />
        <Route
          exact
          path={routes.help.article}
          component={screens.ArticleDetailController}
        />
        <Route
          exact
          path={routes.help.subcategory}
          component={screens.SubcategoryListController}
        />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
};
export default Main;
