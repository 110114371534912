import * as React from 'react';
import styled from 'styled-components';
import { GlobalStyles } from './LightBox';
import Spinner from '../modules/knowledgeBase/modules/articleDetail/Spinner';

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(40, 48, 68, 0.6);
  z-index: 13;
  left: 0;
  top: 0;
  overflow: hidden;

  .spinner {
    padding: 8px;
    border-radius: 50%;
    margin-top: 8px;
    background-color: #fff;
  }
`;
const Processing = (props: any) => {
  const { isOpen } = props;
  if (isOpen)
    return (
      <Container>
        {isOpen ? <GlobalStyles /> : <div />}
        <Spinner colorText="#fff" />
      </Container>
    );
  return null;
};

export default Processing;
