import * as React from 'react';

const fieldsDefault = {
  articleCode: '',
  thumpUp: true,
  feedback: ''
};

const fieldsErrorsDefault = {
  thumpUp: false,
  feedback: false
};

const useFields = () => {
  const [fields, setFields] = React.useState(fieldsDefault);
  const [errors, setErrors] = React.useState(fieldsErrorsDefault);

  const setFieldValue = ({ name, value }: { name: string; value: string }) =>
    setFields({ ...fields, [name]: value });
  const setFieldError = ({ name, value }: { name: string; value: string }) =>
    setErrors({ ...errors, [name]: value });

  const formIsValid = !!(
    fields.articleCode &&
    fields.feedback &&
    fields.thumpUp &&
    errors.feedback &&
    errors.thumpUp
  );

  return {
    fields,
    errors,
    formIsValid,
    setFieldValue,
    setFieldError
  };
};

// const fieldsDefault = {
//   procedureId: '',
//   phone: '',
//   email: '',
//   detail: '',
//   authorize: false,
//   files: []
// };
// const fieldsErrorsDefault = {
//   email: false,
//   detail: false,
//   files: false,
//   phone: false
// };
// const useFields = () => {
//   const [fields, setFields] = React.useState(fieldsDefault);
//   const [errors, setErrors] = React.useState(fieldsErrorsDefault);
//   const setFieldValue = ({ name, value }: { name: string; value: string }) =>
//     setFields({ ...fields, [name]: value });
//   const setFieldError = ({ name, value }: { name: string; value: string }) =>
//     setErrors({ ...errors, [name]: value });
//   const formIsValid = !!(
//     fields.procedureId &&
//     fields.phone &&
//     fields.email &&
//     fields.detail &&
//     !errors.phone &&
//     !errors.email &&
//     !errors.detail &&
//     !errors.files
//   );
//   const formIsEditing = !!(
//     fields.procedureId ||
//     fields.phone ||
//     fields.email ||
//     fields.detail ||
//     errors.files ||
//     fields.files.length ||
//     fields.authorize
//   );
//   return {
//     fields,
//     errors,
//     formIsValid,
//     formIsEditing,
//     setFieldValue,
//     setFieldError
//   };
// };

export default useFields;
