import ProfileDataStore from '../../../datastores/ProfileDataStore';

export default class ProfileDataStoreApiRest implements ProfileDataStore {
  getProfile() {
    throw new Error('Method not implemented.');
  }

  getProfilePhoto() {
    throw new Error('Method not implemented.');
  }

  getModalityOfStudy() {
    throw new Error('Method not implemented.');
  }

  getProfileTurn() {
    throw new Error('Method not implemented.');
  }
}
