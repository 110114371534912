import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import constants from '../../../../../core/constants';
import BrandHelp from '../../../BrandWrapperHelp';
import UserMenuHelp from '../../../UserMenuHelp';

const WrapperHeader = styled.div.attrs({
  className: 'mx-auto justify-between grid grid-cols-2 wrapperHeader'
})`
  z-index: 2;
  &.hide {
    z-index: 1;
  }
`;

const LeftSideHeader = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const RightSideHeader = styled.div`
  display: flex;
  @media (max-width: 768px) {
  }
`;

interface HeaderProps {
  loginAction: () => void;
  logoutAction: () => void;
  clearCache: () => void;
}

function Header({ loginAction, logoutAction, clearCache }: HeaderProps) {
  const { data: dataProfile } = useSelector((state: any) => state.profile);
  const { data: profilePhoto } = useSelector(
    (state: any) => state.profilePhoto
  );
  const { data: cacheData, isLoading: loadingClearCache } = useSelector(
    (state: any) => state.clearCache
  );

  useEffect(() => {
    if (cacheData?.isOk) window.location.reload();
  }, [cacheData]);

  const cleanCache = {
    labelText: 'Limpiar caché',
    iconName: 'refresh-ccw',
    modeClick: true,
    urlClick: '',
    handleClick: clearCache
  };
  const closeSession = {
    labelText: 'Cerrar sesión',
    iconName: 'log-out',
    modeClick: true,
    urlClick: '/logout',
    handleClick: logoutAction
  };

  const menu = dataProfile?.isAdm ? [cleanCache, closeSession] : [closeSession];

  const dataContentMenu = {
    label: '',
    menu,
    user: [
      {
        userName: dataProfile?.firstName.toLowerCase(),
        userAvatar: profilePhoto || constants.DEFAULT_PHOTO
      }
    ]
  };

  return (
    <WrapperHeader>
      <LeftSideHeader>
        <BrandHelp />
      </LeftSideHeader>
      <RightSideHeader>
        <UserMenuHelp
          menu={dataProfile ? dataContentMenu?.menu : null}
          user={dataProfile ? dataContentMenu?.user : null}
          loginAction={loginAction}
          loadingClearCache={loadingClearCache}
        />
      </RightSideHeader>
    </WrapperHeader>
  );
}

export default Header;
