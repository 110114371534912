import { put, call } from 'redux-saga/effects';
import KnowledgeBaseDataRepository from '../../../../data/repositories/KnowledgeBaseRepository';
import KnowledgeBaseRepository from '../../../repositories/KnowledgeBaseRepository';
import defaultDataCategories from './categories.json';
import { Types } from './actions';

export default function* saga(): any {
  try {
    yield put({ type: Types.GET_CATEGORIES_REQUEST });
    const knowledgeBaseRepository: KnowledgeBaseRepository =
      new KnowledgeBaseDataRepository();
    const categoriesResponse = yield call(
      knowledgeBaseRepository.getCategories
    );
    if (!categoriesResponse) {
      yield put({
        type: Types.GET_CATEGORIES_FAILURE,
        error: true
      });
      return;
    }

    const dataCategories = defaultDataCategories.data
      .map((el: any) => categoriesResponse.find((f: any) => f.code === el.code))
      .filter((c) => !!c);

    yield put({
      type: Types.GET_CATEGORIES_SUCCESS,
      data: dataCategories
    });
  } catch (error: any) {
    yield put({
      type: Types.GET_CATEGORIES_FAILURE,
      error: true
    });
  }
}
