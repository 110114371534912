import { createReducer } from 'reduxsauce';
import { Types } from './actions';
import reducerBase from '../../base/reducerBase';

const { request, success, failure } = reducerBase();

const INITIAL_STATE = {
  isLoading: false,
  error: null
};

export default createReducer(INITIAL_STATE, {
  [Types.CLEAR_CACHE_REQUEST]: request,
  [Types.CLEAR_CACHE_SUCCESS]: success,
  [Types.CLEAR_CACHE_FAILURE]: failure
});
