import styled from 'styled-components';
import constants from '../../../../core/constants';
import Banner from '../../../../assets/images/knowledgebase/banner.svg';
import BannerXl from '../../../../assets/images/knowledgebase/banner_xl.svg';

const Header = styled.div.attrs({
  className: 'header'
})`
  background-color: var(--nb-background-banner);
  width: 100%;
  display: flex;
  justify-content: center;

  @media (min-width: 768px) {
    background: url(${Banner}) no-repeat center;
    background-color: var(--nb-background-1);
  }
  @media (min-width: 1280px) {
    background: url(${BannerXl}) no-repeat center;
    background-color: var(--nb-background-1);
  }
`;

const Title = styled.div`
  color: var(--nb-text-secondary);
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-start;
  }
`;

const ContainerKnowledgeBase = styled.div.attrs({
  className: 'containerKnowledgeBase'
})``;

const ContainerList = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 16px;
  padding-bottom: 52px;
  background-color: #fff;

  display: flex;
  justify-content: space-around;
  align-items: baseline;

  @media (min-width: ${constants.BREAKPOINT_SIZE}px) {
    padding-top: 24px;
  }
`;

const CategoryCard = styled.button.attrs((props: any) => ({
  className: 'fadeTransition categoryCard',
  img: props.img
}))`
  background: var(--nb-background-UTP) url('${(props) => props.img}') center top
    no-repeat;
  background-position: 50% 20%;
  // min-width: 156px;
  // max-width: 156px;
  // width: 45%;
  // margin: 8px;
  &:active,
  :hover {
    border-radius: 16px;
    background-color: var(--nb-background-UTP-hover);
  }
  &:focus {
    outline: 0;
    background-color: var(--nb-background-UTP-focus);
    border-radius: 16px;
  }
  & label {
    margin-top: 16px;
    height: 40px;
  }

  &:before {
    content: ' ';
    width: 100%;
    height: 68px;
  }

  @media (min-width: ${constants.BREAKPOINT_SIZE}px) {
    // min-width: 160px;
    // max-width: 160px;
    // margin: 16px;
  }
`;

const InputSearchWrapper = styled.div.attrs({
  className: 'inputSearchWrapper'
})`
  &:hover,
  &:hover .inputSearch,
  &:hover .inputButton {
    background-color: var(--nb-background-1);
    cursor: pointer;
  }
  mark {
    color: var(--nb-mark);
    background-color: transparent;
    font-weight: 900;
  }
  input[type='text'].inputSearch:hover::placeholder {
    color: var(--text-text02);
  }
  input[type='text'].inputSearch {
    border-width: 0px;
    outline: 0;
    // background-color: transparent;
  }
  input[type='text'].inputSearch:focus ~ .inputButton {
    background-color: var(--nb-background-1);
  }
  input[type='text']:focus {
    outline: 0;
    background-color: var(--nb-background-1);
    border-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  input[type='text'].inputSearch::placeholder {
    color: var(--nb-text-1);
  }
  button.inputButton {
    outline: 0;
    padding: 0 18px;
    display: flex;
    cursor: default;
    height: 40px;
    align-items: center;
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  input[type='text'].inputSearchFilled ~ button.inputButton {
    cursor: pointer;
  }
  .menu-wrapper {
    width: 100%;
    min-width: 153px;
    z-index: 1;
    position: absolute;
    background-color: #fff;
    border-radius: 4px;
    max-height: 216px;
    overflow: auto;
    top: 44px;
    text-align: left;
  }
  .menu-wrapper:hover {
    background-color: #fff;
  }
  .menu-wrapper:focus {
    background-color: #fff;
  }
  ul {
    padding: 8px 0;
  }
  li button {
    width: 100%;
    height: 40px;
    text-align: left;
    padding: 10px 16px;
    // display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  li button cc-icon {
    margin-right: 10px;
  }
  li button:focus {
    outline: 0;
  }
  li button.empty {
    color: #4f6168;
    display: flex;
  }
  li:hover {
    background: var(--nb-search-option-hover);
  }
  li:focus {
    background: var(--nb-search-option-focus);
  }
  li:active {
    background: var(--nb-search-option-active);
  }
  input[type='text'].inputSearch {
    width: 100%;
  }
  @media (min-width: ${constants.BREAKPOINT_SIZE}px) {
    input[type='text'].inputSearch {
      width: 100%;
    }
  }
`;

const CenterWrapper = styled.div.attrs({
  className: 'centerWrapper'
})`
  margin: 0 auto;
`;

const Buttoninfo = styled.a`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #5b36f2;
  border: 1px solid #5b36f2;
  border-radius: 4px;
  padding: 10px 16px;
  margin-right: 9px;

  &:hover {
    background-color: #e2dffd;
    cursor: pointer;
  }

  &:focus {
    background-color: #c6c0fc;
    outline: 0;
  }

  &:active {
    background-color: #c6c0fc;
  }
`;

const Buttonstyle = styled.div`
  padding: 24px 0px 28px 0px;
  display: flex;
  align-items: center;
`;

const ButtonClose = styled.button`
  width: 45px;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 8px;
  gap: 10px;

  &:hover {
    background-color: #001d5b;
    border-radius: 4px;
  }

  &:focus {
    background-color: #002e84;
    border-radius: 4px;
  }

  &:active {
    background-color: #002e84;
    border-radius: 4px;
  }
  @media (min-width: 992px) {
    width: 55px;
    margin-left: 15px;
  }
`;

const SectionDigitalPlatforms = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 40px 0px;
  width: 100%;
`;

const TitleDigitalPlatforms = styled.h1`
  font-weight: 900;
  font-size: 18px;
  line-height: 28px;
  color: #161d1f;
`;

const RowCardDigitalPlatforms = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  gap: 24px;

  ::-webkit-scrollbar {
    display: none;
  }
`;

interface CardDigitalPlatformsProps {
  height?: string;
  width?: string;
}

const CardDigitalPlatforms = styled.button<CardDigitalPlatformsProps>`
  box-shadow: 0px 1.2px 4px #eaeef1;
  border-radius: 4px;
  background: #ffffff;
  display: flex;
  padding: 16px 8px;
  height: ${(props) => props.height ?? '88px'};
  gap: 10px;
  width: ${(props) => props.width ?? '264px'};

  &:hover {
    background: #d5e5f0;
  }

  &:focus,
  active {
    background: #a8cbe2;
  }
`;

interface ImagenDigitalPlatformsProps {
  height?: string;
  width?: string;
  margin?: string;
}

const ImagenDigitalPlatforms = styled.img<ImagenDigitalPlatformsProps>`
  max-width: none;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
`;

const ContainerInterestsExternal = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: space-around;
`;

const TitlesInterestsExternal = styled.div`
  display: flex;
  flex-direction: column;
  width: 166px;
  text-align: start;
`;

interface TitleInterestsProps {
  size?: string;
  lineHeight?: string;
}

const TitleInterests = styled.h1<TitleInterestsProps>`
  font-weight: 700;
  font-size: ${(props) => props.size ?? '14px'};
  line-height: ${(props) => props.lineHeight ?? '20px'};
  color: #161d1f;
  width: 150px;
`;

const SubTitleInterests = styled.h1`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #4f6168;
  width: 100%;
`;

export {
  Header,
  Title,
  ContainerList,
  CategoryCard,
  InputSearchWrapper,
  CenterWrapper,
  ContainerKnowledgeBase,
  Buttoninfo,
  Buttonstyle,
  ButtonClose,
  SectionDigitalPlatforms,
  TitleDigitalPlatforms,
  RowCardDigitalPlatforms,
  CardDigitalPlatforms,
  ImagenDigitalPlatforms,
  ContainerInterestsExternal,
  TitlesInterestsExternal,
  TitleInterests,
  SubTitleInterests
};
