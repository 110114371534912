import { put, call } from 'redux-saga/effects';
import { Types } from './actions';
import ProfileRepository from '../../repositories/ProfileRepository';
import ProfileDataRepository from '../../../data/repositories/ProfileDataRepository';

export default function* execute(): any {
  try {
    yield put({ type: Types.GET_PROFILE_PHOTO_REQUEST });
    const profileRepository: ProfileRepository = new ProfileDataRepository();
    const profileResponse = yield call(profileRepository.getProfilePhoto);
    yield put({
      type: Types.GET_PROFILE_PHOTO_SUCCESS,
      data: profileResponse
    });
  } catch (error) {
    yield put({
      type: Types.GET_PROFILE_PHOTO_FAILURE,
      error: true
    });
  }
}
