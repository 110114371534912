import { put, call, select } from 'redux-saga/effects';
import KnowledgeBaseDataRepository from '../../../../data/repositories/KnowledgeBaseRepository';
import KnowledgeBaseRepository from '../../../repositories/KnowledgeBaseRepository';
import { Types } from './actions';

export default function* saga(action: any): any {
  try {
    const { request } = action;
    yield put({ type: Types.REGISTER_COMMENT_REQUEST });
    const knowledgeBaseRepository: KnowledgeBaseRepository =
      new KnowledgeBaseDataRepository();
    const { data } = yield select((state) => state.profile);
    const procedureResponse = yield call(
      knowledgeBaseRepository.sendFeedback,
      request?.articleCode,
      request?.thumpUp,
      request?.feedback,
      !!data
    );
    if (!procedureResponse) {
      yield put({
        type: Types.REGISTER_COMMENT_FAILURE,
        error: true
      });
      return;
    }
    yield put({
      type: Types.REGISTER_COMMENT_SUCCESS,
      data: procedureResponse
    });
  } catch (error) {
    yield put({
      type: Types.REGISTER_COMMENT_FAILURE,
      error: {
        title: 'Ocurrió un problema inesperado',
        subtitle: 'Por favor, vuelve a intentarlo.'
      }
    });
  }
}
