import KnowledgeBaseRepository from '../../domain/repositories/KnowledgeBaseRepository';
import KnowledgeBaseDataStore from '../datastores/KnowledgeBaseDataStore';
import KnowledgeBaseDataStoreFactory from '../factories/KnowledgeBaseDataStoreFactory';

export default class KnowledgeBaseDataRepository
  implements KnowledgeBaseRepository
{
  // knowledgeBaseDataStore: KnowledgeBaseDataStore =
  //   KnowledgeBaseDataStoreFactory.create();

  sendFeedback(
    articleCode: string,
    thumpsUp: boolean,
    feedback: string,
    isAuthenticated: boolean
  ): any {
    const knowledgeBaseDataStore: KnowledgeBaseDataStore =
      KnowledgeBaseDataStoreFactory.create();
    return knowledgeBaseDataStore.sendFeedback(
      articleCode,
      thumpsUp,
      feedback,
      isAuthenticated
    );
  }

  getCategories(): any {
    const knowledgeBaseDataStore: KnowledgeBaseDataStore =
      KnowledgeBaseDataStoreFactory.create();
    return knowledgeBaseDataStore.getCategories();
  }

  getSubCategories(categoryCode: string, isAuthenticated: boolean): any {
    const knowledgeBaseDataStore: KnowledgeBaseDataStore =
      KnowledgeBaseDataStoreFactory.create();
    return knowledgeBaseDataStore.getSubCategories(
      categoryCode,
      isAuthenticated
    );
  }

  getMetadata(isAuthenticated: boolean): any {
    const knowledgeBaseDataStore: KnowledgeBaseDataStore =
      KnowledgeBaseDataStoreFactory.create();
    return knowledgeBaseDataStore.getMetadata(isAuthenticated);
  }

  getArticlesByCategory(
    categoryCode: string,
    subcategoryCode: string,
    isAuthenticated: boolean
  ): any {
    const knowledgeBaseDataStore: KnowledgeBaseDataStore =
      KnowledgeBaseDataStoreFactory.create();
    return knowledgeBaseDataStore.getArticlesByCategory(
      categoryCode,
      subcategoryCode,
      isAuthenticated
    );
  }

  getArticle(articleCode: string): any {
    const knowledgeBaseDataStore: KnowledgeBaseDataStore =
      KnowledgeBaseDataStoreFactory.create();
    return knowledgeBaseDataStore.getArticle(articleCode);
  }

  refreshData(): boolean {
    const knowledgeBaseDataStore: KnowledgeBaseDataStore =
      KnowledgeBaseDataStoreFactory.create();
    return knowledgeBaseDataStore.refreshData();
  }
}
