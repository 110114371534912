import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  // View actions
  registerComment: ['request'],
  registerCommentClear: null,
  registerCommentErrorClear: null,
  // Reducer
  registerCommentRequest: null,
  registerCommentSuccess: null,
  registerCommentFailure: ['error']
});

export { Creators, Types };
