import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Creators as categoriesActions } from '../../../domain/useCases/knowledgeBase/getCategoriesUseCase/actions';
import { Creators as subcategoriesActions } from '../../../domain/useCases/knowledgeBase/getSubcategoriesUseCase/actions';
import { Creators as articlesActions } from '../../../domain/useCases/knowledgeBase/getArticleListUseCase/actions';
import { Creators as selectArticleActions } from '../../../domain/useCases/knowledgeBase/selectCategory/actions';
import { Creators as breadcrumbsActions } from '../../../domain/useCases/knowledgeBase/breadcrumbUseCase/actions';
import { Creators as metadataActions } from '../../../domain/useCases/knowledgeBase/getMetadataUseCase/actions';
import { Creators as registerQualificationActions } from '../../../domain/useCases/knowledgeBase/registerCommentArticleUseCase/actions';
import routes from '../../components/Routes/routingMap';
import KnowledgeBaseView from './KnowledgeBaseView';
import { Article, Category } from './types';
import XCheckpointManager from '../../../core/managers/xcheckpointManager';

export const handlerRedirectNewPage = (url: any) => {
  window.open(url, '_blank');
};

const KnowledgeBaseController = () => {
  const dispatch = useDispatch();
  // const { token } = useKeycloak();
  const history = useHistory();

  const [loadStart, setLoadStart] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [metadataFound, setMetadataFound] = React.useState<Article[]>([]);

  const { isLoading: isLoadingCategories, data: categoryList } = useSelector(
    (state: any) => state.categoryKnowledgeBase
  );

  const { data: metadata, isLoading: isLoadingMetadata } = useSelector(
    (state: any) => state.metadataKnowledgeBase
  );
  // const isValidToken = !!token;

  const selectCategory = (category: Category) => {
    // dispatch(categoriesActions.selectedCategory(category));
    dispatch(breadcrumbsActions.setPosition(1));
    history.push(
      routes.help.subcategory.replaceAll(':categoryId', category.code)
    );
  };
  const filterMetadata = (key: string) => {
    setMetadataFound(
      metadata?.filter(
        (m: Article) =>
          m?.name?.toLowerCase().includes(key.toLowerCase()) ||
          m?.tags?.toLowerCase().includes(key.toLowerCase())
      )
    );
  };

  const changeSearchText = (text: string) => {
    setSearchText(text);
    filterMetadata(text);
  };

  const selectArticle = (article: Article) => {
    dispatch(registerQualificationActions.registerCommentClear());
    dispatch(articlesActions.selectedArticle(article));
    dispatch(subcategoriesActions.selectSubCategory(null));
    dispatch(breadcrumbsActions.setPosition(3));
    dispatch(selectArticleActions.selectCategoryClear());
    history.push(routes.help.article.replace(':articleId', article.code));
  };

  useEffect(() => {
    XCheckpointManager.registerStart('Inicio');

    if (!loadStart) {
      // previamente en un useeffect inicial
      // dispatch(categoriesActions.selectedCategory(null));
      dispatch(subcategoriesActions.selectSubCategory(null));
      dispatch(articlesActions.selectedArticle(null));
      dispatch(breadcrumbsActions.setPosition(0));
      dispatch(categoriesActions.getCategories());
      dispatch(metadataActions.getMetadata());
      // if (token) {
      //   dispatch(profileActions.getProfile());
      // }
    }
    setLoadStart(true);
  }, []);

  useEffect(() => {
    if (categoryList && metadataFound && loadStart) {
      XCheckpointManager.registerEnd('Inicio');
      setLoadStart(false);
    }
  }, [categoryList, metadataFound]);

  return (
    <KnowledgeBaseView
      isValidToken
      categoryList={categoryList}
      metadata={metadataFound}
      isLoadingMetadata={isLoadingMetadata}
      isLoadingCategories={isLoadingCategories}
      searchText={searchText}
      changeSearchText={changeSearchText}
      selectCategory={selectCategory}
      selectArticle={selectArticle}
      handlerRedirectNewPage={handlerRedirectNewPage}
    />
  );
};

export default KnowledgeBaseController;
