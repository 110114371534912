import { put, delay } from 'redux-saga/effects';
import { Types } from './actions';
import ayudaPdf from '../../../../assets/illustrations/ayuda-pdf.svg';

export default function* saga(): any {
  try {
    yield put({ type: Types.GET_ARTICLE_DETAIL_REQUEST });
    yield delay(1000);
    const dataArticle = [
      {
        code: '001',
        name: 'Beca por orfandad',
        last_update: 'Actualización hace 1 mes',
        html: ayudaPdf
      }
    ];
    yield put({
      type: Types.GET_ARTICLE_DETAIL_SUCCESS,
      data: dataArticle
    });
  } catch (error) {
    yield put({
      type: Types.GET_ARTICLE_DETAIL_FAILURE,
      error: true
    });
  }
}
