import React, { useRef } from 'react';
import styled from 'styled-components';
import { CcButton } from '@utp/components-react';

// interface Props {
//   value?: string;
//   onClick?: () => void;
//   className?: string;
//   size?: 'md' | 'lg' | 'sm' | undefined;
//   secondary?: boolean;
//   icon?: string;
//   disable?: boolean;
//   outline?: boolean;
//   clear?: boolean;
//   expand?: boolean;
//   loading?: boolean;
//   submit?: boolean;
//   href?: string;
//   customized?: boolean;
//   children: any;
// }
const StyledButton = styled.div.attrs((props: any) => ({
  borderRadius: props.borderRadius,
  padding: props.padding,
  backgroundColor: props.backgroundColor,
  shadow: props.shadow,
  maxWidth: props.maxWidth,
  minWidth: props.minWidth,
  maxHeight: props.maxHeight,
  minHeight: props.minHeight,
  margin: props.margin,
  display: props.display,
  flexDirection: props.flexDirection,
  alignItems: props.alignItems,
  alignContent: props.alignContent,
  justifyContent: props.justifyContent,
  border: props.border,
  height: props.height,
  transform: props.transform,
  transition: props.transition,
  cursor: props.cursor,
  outline: props.outline,
  loading: props.loading,
  width: props.width,
  delayClick: props.delayClick
}))`
  transition: ${(props) => (props.transition ? props.transition : 'none')};
  width: ${(props) => (props.width ? props.width : 'auto')};
  margin: ${(props) => (props.margin ? props.margin : 'initial')};
  border: ${(props) => (props.border ? props.border : 'none')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : 'none'};
  padding: ${(props) => (props.padding ? props.padding : 'none')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'auto')};
  min-width: ${(props) => (props.minWidth ? props.minWidth : '1px')};
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : '100%')};
  min-height: ${(props) => (props.minHeight ? props.minHeight : '1px')};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : 'transparent'};
  box-shadow: ${(props) => (props.shadow ? props.shadow : 'none')};
  display: ${(props) => (props.display ? props.display : 'flex')};
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : 'column'};
  align-items: ${(props) =>
    props.alignItems ? props.alignItems : 'flex-start'};
  align-content: ${(props) =>
    props.alignContent ? props.alignContent : 'flex-start'};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  height: ${(props) => (props.height ? props.height : 'auto')};
  transform: ${(props) => (props.transform ? props.transform : 'none')};
  cursor: ${(props) => (props.cursor ? props.cursor : 'pointer')};
  outline: ${(props) => (props.outline ? props.outline : 'none')};

  /* &:focus {
    outline: ${(props) => (props.outline ? props.outline : 'none')};
  } */
`;

function Button(props: any) {
  const {
    value,
    className,
    secondary,
    icon,
    size,
    disable,
    outline,
    clear,
    expand,
    onClick,
    // onBlur,
    loading,
    submit,
    href,
    customized,
    children,
    tag,
    delayClick,
    ref,
    disabled,
    onKeyDown
  } = props;

  const ButtonRef: any = useRef<any>();
  if (customized) {
    return (
      <StyledButton
        data-testid="button-ds-customized"
        disabled={disabled}
        ref={ref || ButtonRef}
        as={tag}
        {...props}
        onClick={
          delayClick
            ? () => {
                setTimeout(() => {
                  if (typeof onClick === 'function' && onClick) {
                    onClick();
                  }

                  if (ref) {
                    ref?.current?.blur();
                  } else if (ButtonRef !== null) {
                    ButtonRef?.current?.blur();
                  }
                }, delayClick);
              }
            : onClick
        }
        onKeyDown={onKeyDown}
      >
        {children}
      </StyledButton>
    );
  }
  return (
    <CcButton
      data-testid="button-ds"
      href={href}
      className={className}
      size={size}
      color={(secondary && 'secondary') || 'primary'}
      iconName={icon || ''}
      disabled={disable}
      fill={(outline && 'outline') || (clear && 'clear') || 'solid'}
      expand={expand}
      onClick={onClick}
      loading={loading}
      type={submit ? 'submit' : 'button'}
    >
      {value || ''}
    </CcButton>
  );
}

// Button.defaultProps = {
//   value: '',
//   secondary: false,
//   className: '',
//   size: 'md',
//   icon: '',
//   disable: false,
//   outline: false,
//   clear: false,
//   expand: false,
//   loading: false,
//   submit: false,
//   onClick: undefined,
//   href: null,
//   styled: false
// };

export default Button;
