import { v4 as uuidv4 } from 'uuid';
import XCheckpointDataRepository from '../../data/repositories/XCheckpointDataRepository';

export default class XCheckpointManager {
  // public static registerLog(eventName: string) {
  //   const uuid = localStorage.getItem('uuid') || uuidv4();
  //   const xcheckpointRepository = new XCheckpointDataRepository();
  //   xcheckpointRepository.writeLog(eventName, uuid);
  // }

  private static writeLog(eventName: string, uuid: string) {
    const xcheckpointRepository = new XCheckpointDataRepository();
    xcheckpointRepository.writeLog(eventName, uuid);
  }

  public static registerStart(eventName: string) {
    const uuid = uuidv4();
    localStorage.setItem('uuid', uuid);
    XCheckpointManager.writeLog(eventName, uuid);
  }

  public static registerEnd(eventName: string) {
    const uuid = localStorage.getItem('uuid') || uuidv4();
    XCheckpointManager.writeLog(eventName, uuid);
  }

  // public static createUuid() {
  //   const uuid = uuidv4();
  //   localStorage.setItem('uuid', uuid);
  // }

  // public static clearUuid() {
  //   localStorage.removeItem('uuid');
  // }
}
