import React from 'react';
import Spinner from '../knowledgeBase/modules/articleDetail/Spinner';

const SplashViewController: React.FC = () => (
  <div>
    <Spinner />
  </div>
);

export default SplashViewController;
