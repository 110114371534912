import { createReducer } from 'reduxsauce';
import { Types } from './actions';

const INITIAL_STATE = {
  isLoading: false,
  error: null
};

const request = (state: any) => ({
  ...state,
  isLoading: true,
  error: null,
  data: null
});

const success = (state: any, action: any) => ({
  ...state,
  isLoading: false,
  data: action.data,
  error: null
});

const failure = (state: any, action: any) => ({
  ...state,
  isLoading: false,
  data: null,
  error: action.error
});

const select = (state: any, action: any) => ({
  ...state,
  selectedArticle: action.article
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_ARTICLES_REQUEST]: request,
  [Types.GET_ARTICLES_SUCCESS]: success,
  [Types.GET_ARTICLES_FAILURE]: failure,
  [Types.SELECTED_ARTICLE]: select
});
