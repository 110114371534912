import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  // View actions
  getArticles: ['categoryCode', 'subCategoryCode'],
  selectedArticle: ['article'],
  // Reducer
  getArticlesRequest: null,
  getArticlesSuccess: null,
  getArticlesFailure: ['error']
});

export { Creators, Types };
