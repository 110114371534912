import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { WrapModal } from './styles';
import { Container, Layer } from './components';

export const GlobalStyles = createGlobalStyle`
   body {
    overflow-y:hidden !important;
  }
`;
const LightBox = (props: any) => {
  const {
    children,
    handleCloseModal,
    IsModalOpen,
    buttonClose,
    autoCloseLayer,
    backgroundColor
  } = props;
  const handleAutoClose = () => {
    // handleCloseModal();
  };

  return (
    <WrapModal
      className={`${IsModalOpen ? 'active' : ''}`}
      data-testid="cmp-LightBox"
    >
      {IsModalOpen ? <GlobalStyles /> : <div />}

      <Layer
        handleClose={handleCloseModal}
        onClick={autoCloseLayer ? handleAutoClose : null}
      />
      <Container
        buttonClose={buttonClose}
        handleClose={handleCloseModal}
        backgroundColor={backgroundColor}
      >
        {children}
      </Container>
    </WrapModal>
  );
};

export default LightBox;
