import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import routes from '../../../../components/Routes/routingMap';
import { Creators as breadcrumbsActions } from '../../../../../domain/useCases/knowledgeBase/breadcrumbUseCase/actions';
import { Creators as articlesActions } from '../../../../../domain/useCases/knowledgeBase/getArticleListUseCase/actions';
// import { Creators as articleActions } from '../../../../../domain/useCases/knowledgeBase/getArticleUseCase/actions';
import { Creators as registerQualificationActions } from '../../../../../domain/useCases/knowledgeBase/registerCommentArticleUseCase/actions';

import { Article } from '../../types';
import ArticleListView from './ArticleListView';

const ArticleListController = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { data: articles, isLoading: articlesIsLoading } = useSelector(
    (state: any) => state.articlesKnowledgeBase
  );
  const { selectedSubCategory } = useSelector(
    (state: any) => state.subCategoryKnowledgeBase
  );

  if (!selectedSubCategory) {
    history.push(routes.help.home);
  }

  const selectArticle = (article: Article) => {
    dispatch(articlesActions.selectedArticle(article));
    // dispatch(articleActions.getArticle(article.code));
    dispatch(breadcrumbsActions.setPosition(3));
    dispatch(registerQualificationActions.registerCommentClear());
    history.push(routes.help.article.replace(':articleId', article.code));
  };
  useEffect(() => {
    dispatch(articlesActions.selectedArticle(null));
    dispatch(breadcrumbsActions.setPosition(2));
  }, []);
  return (
    <ArticleListView
      selectedSubCategory={selectedSubCategory}
      articles={articles}
      selectArticle={selectArticle}
      articlesIsLoading={articlesIsLoading}
    />
  );
};

export default ArticleListController;
