import React from 'react';
import { CcNavbarWeb } from '@utp/components-react';

function Navbar() {
  return (
    <div>
      <CcNavbarWeb />
    </div>
  );
}

Navbar.defaultProps = {};

export default Navbar;
