import React from 'react';
import { CcIcon } from '@utp/components-react';
import styled from 'styled-components';
import paletteUTP from '../../core/config/theme/utpColors';

const MenuWrapper = styled.div`
  background: ${paletteUTP.menuBackgroundGradient2};
  background: linear-gradient(
    222deg,
    ${paletteUTP.menuBackgroundGradient2} -56%,
    ${paletteUTP.menuBackgroundGradient1} 114%
  );
  text-align: center;
  min-height: 56px;
  width: 56px;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  @media (min-width: 768px) {
    min-height: 72px;
    width: 280px;
    display: none;
  }
`;

const MenuElement = styled.a`
  text-align: center;
  width: 88px;
  color: #fff;
  min-height: 56px;
  padding: 18px;
  &.active {
    background: ${paletteUTP.menuBackgroundGradient2};
    background: linear-gradient(
      180deg,
      ${paletteUTP.menuBackgroundGradient2} 0%,
      ${paletteUTP.menuBackgroundGradient1} 100%
    );
  }
  @media (min-width: 992px) {
    min-height: 72px;
    padding: 28px 30px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

interface Props {
  currentDevice: string;
  OnOpenMenuMobile: any;
}

function Menu(props: Props) {
  const { currentDevice, OnOpenMenuMobile } = props;
  return (
    <MenuWrapper onClick={OnOpenMenuMobile}>
      <MenuElement>
        <CcIcon size={currentDevice ? 16 : 24} color="secondary" name="menu" />
      </MenuElement>
    </MenuWrapper>
  );
}

Menu.defaultProps = {};

export default Menu;
