/**
 * Available HTTP request methods to use with HttpClient.
 */
export enum HttpMethod {
  Get = 'GET',
  Post = 'post',
  Put = 'PUT',
  Delete = 'DELETE',
  Options = 'OPTIONS',
  Patch = 'PATCH',
  Head = 'HEAD'
}

/**
 * HTTP options to pass into HttpClient.exchange.
 */
export interface IHttpClientOptions {
  /** Optional HTTP request method; defaults to HtppMethod.Get */
  method?: HttpMethod;

  /** Optional HTTP headers to be included in the request */
  headers?: { [name: string]: string };

  /** Optional payload body to be sent */
  data?: any;

  /** Raw options to be passed to the underlying implementation; Please use with care */
  options?: any;

  url?: string;

  params?: URLSearchParams;

  timeout?: number;
}

/**
 * HTTP response from HttpClient.exchange.
 */
export interface IHttpResponse<T> {
  /** HTTP status code */
  status: number;

  /** Optional HTTP headers to server returned */
  headers?: { [name: string]: string };

  /** Optional response data of type T */
  data?: T;
}

/**
 * HTTP request abstraction to be implemented by different frameworks and command line
 * tools.
 */
export interface IHttpClient {
  /**
   * Exchange the given HTTP request with the server at url.
   * @param {string} url
   * @param {HttpClientOptions} options
   * @returns {Promise<HttpResponse<T>>}
   */
  exchange<T>(
    url: string,
    options?: IHttpClientOptions
  ): Promise<IHttpResponse<T>>;
}

/**
 * Default HTTP client options each implementation to should use.
 * @type {{method: HttpMethod; headers: {}; retry: WrapOptions}}
 */
export const DefaultHttpClientOptions: IHttpClientOptions = {
  method: HttpMethod.Get
};
