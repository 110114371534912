import styled from 'styled-components';

const Procedurestooltip = styled.div.attrs((props: any) => ({
  background: props.background,
  bottom: props.bottom,
  fadeout: props.fadeout,
  screenwidth: props.screenwidth,
  minWidth: props.minWidth
}))`
  position: relative;
  display: inline-block;

  .tooltipButton {
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0px;
    // padding: 8px;
    height: 40px;
    border-radius: 4px;
    color: #5b36f2;
  }
  .tooltipButton:hover {
    background-color: #e2dffd;
  }
  button.tooltipButton:focus {
    outline: 1;
    background-color: #c6c0fc;
  }

  .tooltipButton:active ~ div span.procedurestooltiptext {
    opacity: 1;
    animation: fadeIn 0.5s ease-in 5s forwards;
  }

  .procedurestooltiptext {
    background-color: ${(props) => props.background};
    color: #fff;
    border-radius: 6px;
    top: ${(props) => (props.minWidth === '816px' ? '-76px' : '51px')};
    right: 0px;
    padding: 12px 16px;
    width: ${(props) => (props.minWidth === '816px' ? '310px' : '204px')};
    position: absolute;
    z-index: 1;
    opacity: 1;
    animation: cssAnimation 0.5s ease-out 5s forwards;
    display: flex;
  }
  .hideTooltip {
    visibility: hidden;
  }

  .procedurestooltiptext::after {
    content: '';
    position: absolute;
    // bottom: -8px;
    bottom: ${(props) => (props.minWidth === '816px' ? '-8px' : '108px')};
    right: 12px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: ${(props) => (props.minWidth === '816px' ? '8px' : '0px')} solid
      ${(props) => props.background};
    border-bottom: ${(props) => (props.minWidth === '816px' ? '0px' : '8px')}
      solid ${(props) => props.background};
  }

  img {
    max-width: 165%;
  }

  @media (min-width: ${(props) => props.minWidth}) {
    .procedurestooltiptext {
      background-color: ${(props) => props.background};
      color: #fff;
      border-radius: 6px;
      top: -4px;
      left: 46px;
      padding: 12px 16px;
      width: ${(props) => props.screenwidth};
      opacity: 1;
      animation: cssAnimation 0.5s ease-out ${(props) => props.fadeout} forwards;
      /* Position the tooltip */
      position: absolute;
      z-index: 1;
    }
    .procedurestooltiptext::after {
      content: '';
      border-width: 9px 10px 11px 0;
      border-color: transparent ${(props) => props.background};
      position: absolute;
      left: -10px;
      border-radius: 1px;
      bottom: ${(props) => props.bottom};
    }

    .tooltipButton:hover ~ div span.procedurestooltiptext {
      opacity: 1;
      animation: fadeIn 0.5s ease-in ${(props) => props.fadeout} forwards;
    }
  }

  @media (min-width: 1280px) {
    .procedurestooltiptext {
      width: ${(props) => (props.minWidth === '816px' ? '340px' : '330px')};
    }
    .procedurestooltiptext::after {
      content: '';
      border-width: 9px 10px 11px 0;
      border-color: transparent ${(props) => props.background};
      position: absolute;
      left: -10px;
      border-radius: 1px;
      bottom: ${(props) => (props.minWidth === '816px' ? '10px' : '33px')};
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes cssAnimation {
    to {
      opacity: 0;
    }
  }
`;

const TooltipContainer = styled.span.attrs((props: any) => ({
  color: props.color
}))``;

export { Procedurestooltip, TooltipContainer };
