import styled from 'styled-components';
import constants from '../../../core/constants';

// MOBILE FIRST
const WrapLayer = styled.div`
  width: 100%;
  height: 100vh;
  background: #283044;
  z-index: 10;
  position: fixed;
  display: flex;
  opacity: 0.6;
  transition: background 0.2s, display 0.3s linear;
  &.active {
    display: none;
  }

  @media (min-width: ${constants.BREAKPOINT_SIZE}px) {
  }
`;

const WrapContainer = styled.div.attrs((props: any) => ({
  backgroundColor: props.backgroundColor || '#fff'
}))`
  max-width: 312px;
  width: 100%;
  overflow: hidden;
  /* height: 100vh; */
  background-color: ${(props) => props.backgroundColor};
  z-index: 10;
  /* position: fixed; */
  display: block;
  border-radius: 8px;
  transition: position 0.2s, display 0.3s linear;
  & > .icon {
    display: flex;
    justify-content: flex-end;
    min-height: 40px;
    padding: 0px 11px;
    align-items: center;
    cursor: pointer;
  }
  & > .icon cc-icon {
    color: var(--interaction-detail);
    cursor: pointer;
  }
  @media (min-width: ${constants.BREAKPOINT_SIZE}px) {
    max-width: 360px;
  }
`;

const WrapModal = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  display: none;
  z-index: 12;
  top: 0px;
  left: 0px;
  align-items: center;
  justify-content: center;
  &.active {
    display: flex;
  }
`;

export { WrapLayer, WrapContainer, WrapModal };
