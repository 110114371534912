import ProfileRepository from '../../domain/repositories/ProfileRepository';
import ProfileDataStoreFactory from '../factories/ProfileDataStoreFactory';
import ProfileDataStore from '../datastores/ProfileDataStore';

export default class ProfileDataRepository implements ProfileRepository {
  getProfile() {
    const authDataStore: ProfileDataStore = ProfileDataStoreFactory.create();
    return authDataStore.getProfile();
  }

  getProfilePhoto() {
    const authDataStore: ProfileDataStore = ProfileDataStoreFactory.create();
    return authDataStore.getProfilePhoto();
  }

  getModalityOfStudy() {
    const authDataStore: ProfileDataStore = ProfileDataStoreFactory.create();
    return authDataStore.getModalityOfStudy();
  }

  getProfileTurn() {
    const authDataStore: ProfileDataStore = ProfileDataStoreFactory.create();
    return authDataStore.getProfileTurn();
  }
}
