import React from 'react';
import LogoutView from './LogoutView';

const Logout = () => (
  <div>
    <LogoutView />
  </div>
);

export default Logout;
