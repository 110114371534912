import constants from '../constants';

export interface AuthResult {
  token: string;
  refreshToken: string;
  rol: string;
}

const tokenSeparator = ',';

export default class AuthManager {
  public static async setCredentials(authResult: AuthResult): Promise<void> {
    const { token, refreshToken, rol } = authResult;
    await localStorage.setItem(
      constants.AUTH_TOKEN,
      [token, refreshToken, rol].join(tokenSeparator)
    );
  }

  public static getCredentials = () => {
    try {
      const credentials = localStorage.getItem(constants.AUTH_TOKEN);
      if (!credentials) {
        return null;
      }
      const [token, refreshToken, rol] = credentials.split(tokenSeparator);
      return { token, refreshToken, rol };
    } catch (error) {
      return null;
    }
  };

  public static deleteCredentials = async () => {
    await localStorage.removeItem(constants.AUTH_TOKEN);
  };
}
