import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  // View actions
  getProfile: ['token'],
  // Reducer
  getProfileRequest: null,
  getProfileSuccess: null,
  getProfileFailure: ['error'],
  clearProfile: null
});

export { Creators, Types };
