import { createReducer } from 'reduxsauce';
import { Types } from './actions';
import reducerBase from '../../../base/reducerBase';

const { INITIAL_STATE, request, success, failure } = reducerBase();

export default createReducer(INITIAL_STATE, {
  [Types.GET_ARTICLE_DETAIL_REQUEST]: request,
  [Types.GET_ARTICLE_DETAIL_SUCCESS]: success,
  [Types.GET_ARTICLE_DETAIL_FAILURE]: failure
});
