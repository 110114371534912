import colors from '../config/theme/colors';

export default class ColorsHelper {
  public static getColor(color: any) {
    let nameColor = color;

    function getKeyByValue(object: any, value: any) {
      return Object.keys(object).find((key) => object[key] === value);
    }

    // keys.forEach((key: any) => {
    //   const valuesT = colors[key];
    //   console.log(valuesT);
    //   // do stuff with "values"
    // });

    // keys.forEach((value: any) => {});
    // var index = colors.map(function(o: any) { return o.attr1; }).indexOf("john");
    // console.log("index of 'john': " + index);
    const result = getKeyByValue(colors, color);
    // console.log('keys ===>', keys);

    if (result) {
      nameColor = `var(--${result})`;
    }
    // console.log('result ===>', result);
    // console.log('color ===>', nameColor);
    // const getPropValue = (object: any, path = '') =>
    //   path.split('.').reduce((o, x) => (o === undefined ? o : o[x]), object);

    // const e = getPropValue(colors, color);
    // console.log(e);
    return nameColor;
  }
}
