import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import { storageManager } from '../../core/managers';
import {
  getProfileUseCase,
  getCategoriesKnowledgeBaseUseCase,
  getMetadataKnowledgeBaseUseCase,
  getArticlesKnowledgeBaseUseCase,
  getArticleDetailKnowledgeBaseUseCase,
  breadcrumbUseCase,
  subcategoryUseCase,
  getArticleKnowledgeBaseUseCase,
  registerCommentArticleKnowledgeBaseUseCase,
  getProfilePhotoUseCase,
  clearCacheUseCase,
  selectCategoryUseCase
} from '../useCases';

const basePersistConfig = {
  storage: storageManager.create(),
  version: 1,
  blacklist: []
};

const categoryKnowledgeBasePersistConfig = {
  ...basePersistConfig,
  key: 'categoryKnowledgeBase'
};

const articleDetailKnowledgeBasePersistConfig = {
  ...basePersistConfig,
  key: 'articleDetailKnowledgeBase'
};
const categoryKnowledgeBase = persistReducer(
  categoryKnowledgeBasePersistConfig,
  getCategoriesKnowledgeBaseUseCase.reducer
);
const subCategoryKnowledgeBase = persistReducer(
  { ...basePersistConfig, key: 'subCategoryKnowledgeBase' },
  subcategoryUseCase.reducer
);

const metadataKnowledgeBase = persistReducer(
  { ...basePersistConfig, key: 'metadataKnowledgeBase' },
  getMetadataKnowledgeBaseUseCase.reducer
);

const articlesKnowledgeBase = persistReducer(
  { ...basePersistConfig, key: 'articlesKnowledgeBase' },
  getArticlesKnowledgeBaseUseCase.reducer
);

const breadcrumb = persistReducer(
  { ...basePersistConfig, key: 'breadcrumb' },
  breadcrumbUseCase.reducer
);

const articleDetailKnowledgeBase = persistReducer(
  articleDetailKnowledgeBasePersistConfig,
  getArticleDetailKnowledgeBaseUseCase.reducer
);

const profile = persistReducer(
  { ...basePersistConfig, key: 'profile' },
  getProfileUseCase.reducer
);

const profilePhoto = persistReducer(
  {
    ...basePersistConfig,
    key: 'profilePhoto'
  },
  getProfilePhotoUseCase.reducer
);

export default combineReducers({
  profile,
  profilePhoto,
  categoryKnowledgeBase,
  articlesKnowledgeBase,
  metadataKnowledgeBase,
  articleDetailKnowledgeBase,
  breadcrumb,
  subCategoryKnowledgeBase,
  articleKnowledgeBase: getArticleKnowledgeBaseUseCase.reducer,
  registerQualification: registerCommentArticleKnowledgeBaseUseCase.reducer,
  clearCache: clearCacheUseCase.reducer,
  selectCategory: selectCategoryUseCase.reducer
});
