import { createReducer } from 'reduxsauce';
import { Types } from './actions';
import reducerBase from '../../../base/reducerBase';

const { INITIAL_STATE, request, success, failure } = reducerBase();
const select = (state: any, action: any) => ({
  ...state,
  selectedSubCategory: action.subcategory
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_SUB_CATEGORIES_REQUEST]: request,
  [Types.GET_SUB_CATEGORIES_SUCCESS]: success,
  [Types.GET_SUB_CATEGORIES_FAILURE]: failure,
  [Types.SELECT_SUB_CATEGORY]: select
});
