import { createReducer } from 'reduxsauce';
import { Types } from './actions';
import reducerBase from '../../base/reducerBase';

const { INITIAL_STATE, request, success, failure } = reducerBase();

export default createReducer(INITIAL_STATE, {
  [Types.GET_PROFILE_PHOTO_REQUEST]: request,
  [Types.GET_PROFILE_PHOTO_SUCCESS]: success,
  [Types.GET_PROFILE_PHOTO_FAILURE]: failure
});
