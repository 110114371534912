import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
// import './styles.css';
import IconClose from '../../../assets/icons/events/IconClose.svg';
import { ButtonClose } from '../../modules/knowledgeBase/components/styled';
import { Procedurestooltip, TooltipContainer } from './styles';

interface TooltipProps {
  tooltiptext: string;
  children: any;
  hasButton: boolean;
  bgColor: string;
  bottom: string;
  fadeout: string;
  width: string;
  minWidth: string;
}

const TooltipProcedure = (props: TooltipProps) => {
  const {
    tooltiptext,
    children,
    hasButton,
    bgColor,
    bottom,
    fadeout,
    width,
    minWidth
  } = props;
  const [showTooltip, setShowTooltip] = useState(true);
  const CloseToolTip = () => setShowTooltip(false);
  const OpenTooltip = () => setShowTooltip(true);

  return (
    // <div className="procedurestooltip">
    <Procedurestooltip
      background={bgColor}
      bottom={bottom}
      fadeout={fadeout}
      screenwidth={width}
      minWidth={minWidth}
    >
      {children}
      {/* <span
        className="procedurestooltiptext "
        style={hasButton ? { width: '330px', background: '#000F37' } : {}}
      > */}
      <OutsideClickHandler onOutsideClick={OpenTooltip}>
        <TooltipContainer
          className={
            showTooltip
              ? 'procedurestooltiptext'
              : 'procedurestooltiptext hideTooltip'
          }
        >
          <div>{tooltiptext}</div>
          {hasButton ? (
            <ButtonClose onClick={CloseToolTip}>
              <img
                style={{ width: '17px', height: '17px' }}
                src={IconClose}
                alt="IconClose"
              />
            </ButtonClose>
          ) : (
            ''
          )}
        </TooltipContainer>
      </OutsideClickHandler>
    </Procedurestooltip>
    // </span>
    // </div>
  );
};

export default TooltipProcedure;
