import React from 'react';
import { CategoryCard } from './styled';
import { Label } from '../../../components';

const path = '../assets/images/categories/';
interface CategoryProps {
  code: string;
  name: string;
  onClick: () => void;
}
const Category: React.FC<CategoryProps> = ({ code, name, onClick }) => (
  <CategoryCard
    onClick={onClick}
    data-testid="cmp-CategoryCard"
    img={`${path}${code}.svg`}
  >
    {/* <img alt="" src={`${path}${code}.svg`} /> */}
    <Label size={14} lineHeight={20} fontWeight="400" text={name} />
  </CategoryCard>
);

export default Category;
