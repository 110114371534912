const colors = {
  fondo01: '#ecf4f9',
  fondoDeshabilitado: '#eff1f6',

  inteaccionIdentidadMenuDegradado1: '#ff385b',
  inteaccionIdentidadMenuDegradado2: '#ffb59a',
  inteaccionDetalle: '#fc4a61',
  inteaccionPrimario: '#5b36f2',
  interaccionComplementario: '#1a055d',

  neutral01: '#7a959f',
  neutral02: '#283044',
  neutral03: '#ffffff',

  textoPrimario: '#5b36f2',
  textoComplementario: '#1a055d',
  texto01: '#4f6168',
  texto02: '#161d1f',
  texto03: '#ffffff',
  textoDeshabilitado: '#8090bb',

  decorativo01: '#0060ab',
  decorativo02: '#8a4b60',
  decorativo03: '#0079d6',
  decorativo04: '#bd5c00',
  decorativo05: '#83429f',
  decorativo06: '#048583',

  notificacionCirculo: '#fabd00',
  notificacionIcono: '#283044',

  uiBackground01: '#ebf1f5',
  uiBackground02: '#f9fbff',
  uiBackground03: '#ffffff',
  uiBackground04: '#252C3E',
  uiBackground05: 'rgb(24,29,45,0.8)',
  uiBackground06: 'rgb(230,234,240)',
  uiBackground07: 'rgb(24,29,44)',
  uiBackground08: 'rgb(0,145,255)',
  interactive01: '#2A3247',
  interactive01Opacity: 'rgba(42,50,71,.3)',
  interactive02: '#EA3B59',
  supportError: 'rgb(255, 74, 106)',
  email: 'rgb(255, 74, 106)',
  supportSuccess: '#6dd400',
  supportSuccessOpacity30: 'rgba(109,212,0,.3)',
  text01: '#748e98',
  text02: '#4a4a4a',
  text03: '#ffffff',
  text04: '#2a3247',
  text05: '#6dd400',
  text06: '#979EA4',
  text07: '#8282f8',
  text08: '#6363f6',
  link01: '#6236ff',
  link02: '#6363f6',
  field: '#ccd6dc',
  inputNoText: 'rgb(176, 192, 202)',
  blueGray: '#adb7c4',
  disabledText: 'rgb(173, 183, 196)',
  disabledBackground: 'rgb(230, 237, 242)',
  emptyInputBorder: 'rgb(176, 192, 202)',
  inputPlaceholderColor: 'rgb(116, 142, 152)',
  inputPlaceholderColor2: '#979EA4',
  inputBorder: 'rgb(74, 74, 74)',
  focus: '#ebf1f5',
  hoverPrimary: '#5327ef',
  hoverPrimaryLink: '#5327ef',
  hoverSecondary: '#00c5c3',
  primary: '#ebf1f5',
  white: '#FFFFFF',
  black: '#333333',
  inputBackground: '#FFFFFF80',
  tintColorLow: 'rgb(239,234,255)',
  gray: '#4C4C4C',
  backGroundColor: '#f9fbff', // 'rgb(240,245,249)',
  borderColor: 'rgb(230,234,240)',
  borderColor2: 'rgb(230,237,242)',
  borderColor3: 'rgb(234,59,89)',
  borderColor4: 'rgb(234,59,91)',
  borderColor5: 'rgb(98,54,255)',
  borderColor6: 'rgb(34,170,0)',
  borderColor7: '#839be0',
  warningColor: 'rgb(255, 56, 91)',
  warningColor2: 'rgb(255, 74, 106)',
  indicatorColor: '#EA3B59',
  indicatorColorOpacity: 'rgba(234,59,89,.3)',
  textColor: 'rgb(74,74,74)',
  alertColor: 'rgb(255,197,65)',
  successColor: 'rgb(109,212,0)',
  successColor2: 'rgb(34,170,0)',
  shadowColor: '#a0a0a0',
  separatorColorPrimary: '#e6edf2',
  separatorColorSecond: '#f8f5ff',
  separatorColorThree: '#3e475e',
  sliderColor1: '#6363f6',
  sliderColor2: '#ff4a6a',
  sliderColor3: '#4a4a4a',
  backGroundColorMessageProfile: '#363f54',
  iconColor: 'rgb(127,132,144)',
  checkBock: 'rgb(99,99,246)',
  borderCardPaymentGateway: 'rgb(99,99,246)',
  borderFilter50: 'rgba(99,99,246,.5)',

  virtual: 'rgb(0,145,255)',
  virtualOpacity: 'rgba(0,145,255,.3)',

  greenCheck: '#22aa00',
  greenCheck40: 'rgba(34,170,0,.4)',
  borderInput: '#b0c0ca',
  redPink: '#ff385b',
  borderError: 'rgb(249,82,130)',
  messageError: '#ff4a6a',
  bluishGrey: '#748e98',
  backGroundTagDayEvent: '#ddddff',
  iconCloseColor: 'rgb(234,59,89)',
  iconCloseColor2: '#FFFFFF',
  backGroundTranp: 'rgb(28,34,51)'
};

export default colors;
