import constants from '../constants';

export interface UserTokenResult {
  token: string;
  refreshToken: string;
}

const tokenSeparator = ',';

export default class UserTokenManager {
  public static async setCredentials(
    userTokenResult: UserTokenResult
  ): Promise<void> {
    const { token, refreshToken } = userTokenResult;
    await localStorage.setItem(
      constants.AUTH_TOKEN,
      [token, refreshToken].join(tokenSeparator)
    );
  }

  public static getCredentials = () => {
    try {
      const credentials = localStorage.getItem(constants.AUTH_TOKEN);
      if (!credentials) {
        return null;
      }
      const [token, refreshToken] = credentials.split(tokenSeparator);
      return { token, refreshToken };
    } catch (error) {
      return null;
    }
  };

  public static deleteCredentials = async () => {
    await localStorage.removeItem(constants.AUTH_TOKEN);
  };

  public static getReceivedUserToken = async () => {
    const l = window.location.href;
    const url = new URL(l);
    const dataToken = {
      user: url.searchParams.get('user'),
      key: url.searchParams.get('key')
    };

    return dataToken.key;
  };
}
