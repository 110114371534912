import styled from 'styled-components';
import constants from '../../../../../core/constants';

const BreadCrumbElement = styled.button`
  color: #4f6168;
  align-items: center;
  font-size: 14px;
  text-align: left;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (min-width: ${constants.SCREEN_SIZE.md}px) {
    width: auto;
    resize: inherit;
  }

  &:hover {
    outline: 0;
    color: #745ef6;
    text-decoration: underline;
    text-underline-offset: 2px;
  }
  &:focus,
  &:active {
    outline: 0;
    color: #4318cd;
  }
  cc-icon {
    margin: 0px 4px;
    color: #7a959f;
  }
`;

const BreadCrumbContainer = styled.div`
  display: flex;
  margin-top: 26px;
  margin-bottom: 26px;

  button.buttonBack cc-icon {
    color: var(--icon-help);
  }
  button.buttonBack {
    display: flex;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin-right: 8px;
  }
  button.buttonBack:hover {
    background-color: var(--nb-background-buttonback-hover);
  }
  button.buttonBack:focus,
  button.buttonBack:active {
    outline: 0;
    background-color: var(--nb-background-buttonback-focus);
  }
  .breadCrumbElement:not(:last-child) {
    display: none;
  }
  @media (min-width: ${constants.BREAKPOINT_SIZE}px) {
    .breadCrumbElement:not(:last-child) {
      display: block;
    }
  }
`;

export { BreadCrumbContainer, BreadCrumbElement };
