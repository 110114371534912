import React from 'react';
import { CcIcon } from '@utp/components-react';
import styled from 'styled-components';

const NotificationWrapper = styled.div`
  background: #fff;
  text-align: center;
  min-height: 48px;
  max-width: 48px;
  color: var(--neutrals-neutral02);
  padding: 14px;
  display: block;
  float: left;
  border-right: 0px solid #b0c0ca;
  max-height: 48px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  cc-icon {
    color: var(--neutrals-neutral02);
  }
  @media (min-width: 640px) {
    max-width: 88px;
    // border-right: 1px solid #7A959F;
    padding: 14px 30px;
  }
`;

const Notification = (props: any) => {
  const { handleOpenModal, handleSelectAcademicCalendar } = props;

  const executeOpenModal = () => {
    handleSelectAcademicCalendar(0);
    handleOpenModal();
  };
  return (
    <NotificationWrapper
      data-testid="NotificationWrapper"
      className="menu__button"
      onClick={executeOpenModal}
    >
      {/* <NotificationCounter>9+</NotificationCounter> */}
      <CcIcon name="bell" />
    </NotificationWrapper>
  );
};

Notification.defaultProps = {};

export default Notification;
