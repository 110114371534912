import React from 'react';
import { useHistory } from 'react-router';
import IDHelpers from '../../../../../core/helpers/IDHelper';
import { Block, Icon, Label } from '../../../../components';
import BreadCrumb from '../../components/breadCrumb/BreadCrumb';
import {
  Buttoninfo,
  CenterWrapper,
  ContainerKnowledgeBase
} from '../../components/styled';
import { Category, SubCategory } from '../../types';
import Spinner from '../articleDetail/Spinner';
import notFound from '../../../../../assets/illustrations/not_found_course.svg';

const path = '../assets/images/categories/';

interface SubcategoryListViewProps {
  selectedCategory: Category;
  subCategories: SubCategory[];
  isLoading: boolean;
  selectSubCategory: (subcategory: any) => void;
}
const SubcategoryListView = (props: SubcategoryListViewProps) => {
  const history = useHistory();
  const { subCategories, isLoading, selectedCategory, selectSubCategory } =
    props;

  return (
    <ContainerKnowledgeBase data-testid="cmp-subcategory-list-view">
      <CenterWrapper>
        {selectedCategory ? (
          <>
            <BreadCrumb />
            <Block flex className="articleSubTitle">
              <Block
                style={{
                  background: 'white',
                  marginRight: 8,
                  borderRadius: 100,
                  boxShadow: '0px 2px 8px -4px #4A4A4A',
                  width: 32,
                  height: 32,
                  justifyContent: 'center',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <img alt="" src={`${path}${selectedCategory?.code}.svg`} />
              </Block>
              <Label
                text={selectedCategory?.name}
                color="var(--nb-text-secondary)"
                fontWeight="900"
                size={16}
                lineHeight={24}
              />
            </Block>
            <Block customized padding="0 0 56px 0">
              {isLoading && <Spinner />}
              {!isLoading &&
                subCategories?.map((subcategory) => (
                  <button
                    data-testid="cmp-article-list-item"
                    type="button"
                    key={IDHelpers.generateID()}
                    className="articleListItem"
                    onClick={() => selectSubCategory(subcategory)}
                  >
                    <div>
                      {subcategory?.isArticle && (
                        <Icon
                          name="file-text"
                          size={18}
                          color="var(--nb-text-primary)"
                        />
                      )}{' '}
                      {subcategory?.name}
                    </div>
                    {!subcategory?.isArticle && (
                      <Icon
                        name="chevron-right"
                        size={20}
                        color="var(--nb-text-primary)"
                      />
                    )}
                  </button>
                ))}
            </Block>
          </>
        ) : (
          <div
            style={{
              marginTop: '64px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '16px'
            }}
          >
            <img src={notFound} alt="" />
            <span>La categoría no existe</span>
            <Buttoninfo onClick={() => history.push('/')}>
              Ir al inicio
            </Buttoninfo>
          </div>
        )}
      </CenterWrapper>
    </ContainerKnowledgeBase>
  );
};

export default SubcategoryListView;
