import styled from 'styled-components';
import ColorsHelper from '../../../core/helpers/ColorsHelper';

// const a = ColorsHelper.getColor('#fff');
// console.log(a);
const SelectElement = styled.div`
  width: 100%;
  > div {
    width: 100%;
  }
`;
const SelectWrapper = styled.div`
  width: 100%;
  height: 42px;
  overflow: visible;
  position: relative;
  > div {
    cursor: default;
  }

  .menu {
    position: relative;
    display: none;
    width: inherit;
  }
  .menu.active {
    display: block;
    width: inherit;
  }
  .selected {
    padding: 10px 8px 10px 18px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    /* border: 1px solid var(--text-text01); */
    outline: 1px solid var(--text-text-disabled);
    min-width: 153px;
    width: auto;
    justify-content: space-between;
    font-size: 1.4rem;
    font-weight: bold;
    color: var(--neutral-03);
  }
  &.disabled .selected {
    outline: 1px solid #8090bb;
    color: #8090bb;
  }
  .selected.open {
    outline: 1px solid var(--border-color-01);
  }
  .selected.filled {
    outline: 1px solid var(--neutrals-neutral02);
  }

  // .selected:hover {
  //   outline: 1px solid var(--text-primary);
  // }

  // .selected:focus {
  //   border: 1px solid ${ColorsHelper.getColor('#fc4a61')};
  // }
  .selected label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .menu-wrapper {
    width: 100%;
    min-width: 153px;
    // max-width: 426px;
    z-index: 14;
    position: absolute;
    margin-top: 0.8rem;
  }

  .menu-container {
    width: inherit;
    color: black;
    list-style: none;
    padding: 4px 0px;
    max-height: 208px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #fff;
    color: var(--neutral-03);
    border-radius: 0.4rem;
    border-width: 0;
    /* -webkit-box-shadow: 0 4px 16px -8px #b0c0ca;
    box-shadow: 0 4px 16px -8px #b0c0ca; */
    box-shadow: 0px 5px 8px rgba(74, 74, 74, 0.22);
    -webkit-overflow-scrolling: touch;
    will-change: scroll-position;
  }

  .item {
    position: relative;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
    color: #161d1f;
    background-color: #fff;
  }
  .item.active {
    background-color: #e2dffd;
    /* color: var(--text-text01); */
    /* font-weight: bold; */
  }
  .item:hover {
    background-color: #d5e5f0;
    color: #161d1f;
    /* font-weight: normal; */
  }

  .item:focus {
    background-color: #a8cbe2;
    color: #161d1f;
    /* font-weight: normal; */
  }

  .item:active {
    background-color: #a8cbe2;
    color: #161d1f;
    /* font-weight: normal; */
  }

  .selected :focus {
    // border: 0px solid #4318cd;
    outline: 1px solid #4318cd;
    /* outline-offset: -4px; */
  }
  /* option:hover {
    background: limegreen;
  }

  option:focus {
    background: red;
  } */

  .item .item-link {
    display: inline-block;
    border: none;
    margin: 0;
    text-decoration: none;
    cursor: default;
    text-align: left;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: 0px solid #fff;
    outline-offset: -4px;
    padding: 0;
    width: 100%;
    padding: 10px 16px;
  }

  .item .item-link:focus {
    outline: 0px solid #fff;
    outline-offset: -4px;
  }

  &.disabled cc-icon {
    border-width: 0;
    -ms-flex-order: 3;
    order: 3;
    color: #8090bb;
    display: flex;
  }

  .selected cc-icon {
    border-width: 0;
    -ms-flex-order: 3;
    order: 3;
    color: var(--text-primary);
    display: flex;
  }

  .selected cc-loader {
    border-width: 0;
    -ms-flex-order: 3;
    order: 3;
    color: var(--text-primary);
    display: flex;
  }

  .selected cc-icon.calendar {
    border-width: 0;
    -ms-flex-order: 1;
    order: 1;
    color: var(--neutral-03);
    display: flex;
  }

  label {
    display: flex;
    -ms-flex-order: 2;
    order: 2;
  }
  &.disabled label {
    color: #8090bb;
  }
`;
const SelectFilterable = styled.div`
  /* margin: 4px 0px 0px; */
  background: #fff;
  padding: 16px;
  margin-bottom: 1px;
  box-shadow: 0px 5px 8px rgba(74, 74, 74, 0.22);
  .input {
    width: 100%;
  }
  .input__wrapper {
    width: 100%;
  }
`;

export { SelectElement, SelectWrapper, SelectFilterable };
