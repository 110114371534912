import { put, call, select } from 'redux-saga/effects';
import KnowledgeBaseDataRepository from '../../../../data/repositories/KnowledgeBaseRepository';
import KnowledgeBaseRepository from '../../../repositories/KnowledgeBaseRepository';
import { Types } from './actions';

export default function* saga(actions: any): any {
  try {
    const { categoryCode } = actions;
    yield put({ type: Types.GET_SUB_CATEGORIES_REQUEST });
    const knowledgeBaseRepository: KnowledgeBaseRepository =
      new KnowledgeBaseDataRepository();
    const { data } = yield select((state) => state.profile);
    const subCategoriesResponse = yield call(
      knowledgeBaseRepository.getSubCategories,
      categoryCode,
      !!data
    );
    if (!subCategoriesResponse) {
      yield put({
        type: Types.GET_SUB_CATEGORIES_FAILURE,
        error: true
      });
      return;
    }
    yield put({
      type: Types.GET_SUB_CATEGORIES_SUCCESS,
      data: subCategoriesResponse
    });
  } catch (error) {
    yield put({
      type: Types.GET_SUB_CATEGORIES_FAILURE,
      error: true
    });
  }
}
