import { createReducer } from 'reduxsauce';
import { Types } from './actions';
import reducerBase from '../../../base/reducerBase';

const { INITIAL_STATE, request, success, failure, clear } = reducerBase();

export default createReducer(INITIAL_STATE, {
  [Types.SELECT_CATEGORY_REQUEST]: request,
  [Types.SELECT_CATEGORY_SUCCESS]: success,
  [Types.SELECT_CATEGORY_FAILURE]: failure,
  [Types.SELECT_CATEGORY_CLEAR]: clear
});
