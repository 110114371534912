import { createReducer } from 'reduxsauce';
import { Types } from './actions';
import reducerBase from '../../../base/reducerBase';

const { INITIAL_STATE, request, success, failure } = reducerBase();
const clear = () => INITIAL_STATE;
export default createReducer(INITIAL_STATE, {
  [Types.GET_ARTICLE_REQUEST]: request,
  [Types.GET_ARTICLE_SUCCESS]: success,
  [Types.GET_ARTICLE_FAILURE]: failure,
  [Types.GET_ARTICLE_CLEAR]: clear
});
