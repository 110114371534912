import React from 'react';
import styled from 'styled-components';
import constants from '../../core/constants';

const Container = styled.div.attrs((props: any) => ({
  fontWeight: props.fontWeight,
  color: props.color,
  size: props.size,
  lineHeight: props.lineHeight,
  align: props.align,
  width: props.width
}))`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: ${(props) => props.fontWeight};
    color: ${(props) => props.color};
    font-size: ${(props) => props.size}px;
    line-height: ${(props) => props.lineHeight}px;
    align-items: ${(props) => props.align};
    justify-content: ${(props) => props.align};
    display: flex;
    width: ${(props) => props.width};
    text-align: ${(props) => props.align};
  }
  // @media (min-width: ${constants.BREAKPOINT_SIZE}px) {
  //   font-size: 14px;
  // }
`;

interface Props {
  text: any;
  color: string;
  size: number;
  lineHeight: number;
  fontWeight: string;
  className: string;
  level: number;
  align: string;
  width?: string;
}
const Heading = (props: Props) => {
  const {
    text,
    color,
    size,
    lineHeight,
    fontWeight,
    className,
    level,
    align,
    width
  } = props;
  const textColor = color || 'var(--text-text02)'; // ubicar colores por defecto o segun theme
  const textSize = size || 12; // definir tamaños or defecto
  const textLineHeight = lineHeight || 16;
  const alignText = align || 'center';
  const widthContent = width || 'auto';
  let headingElement = <h1>{text}</h1>;
  if (level === 1) headingElement = <h1>{text}</h1>;
  if (level === 2) headingElement = <h2>{text}</h2>;
  if (level === 3) headingElement = <h3>{text}</h3>;
  return (
    <Container
      className={className}
      size={textSize}
      lineHeight={textLineHeight}
      color={textColor}
      fontWeight={fontWeight}
      align={alignText}
      width={widthContent}
    >
      {headingElement}
    </Container>
  );
};
Heading.defaultProps = {
  width: 'auto'
};
export default Heading;
